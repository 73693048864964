import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, UserModel, TokenModel, TransactionModel, PaginationModel, BookingConfirmModel } from '../models';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';
import { UtilHelper } from '../helpers';

@Injectable()
export class TransactionService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  public create(data): Promise<TransactionModel> {
    const obj: any = TransactionModel.toRequest(data);
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION, obj)
      .then((res) => {
        return JsonMapper.deserialize(TransactionModel, res);
      });
  }

  /**
   * Get list variable
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {
    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(TransactionModel, res.data);
        return res;
      });
  }

  public findById(id: string): Promise<TransactionModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/' + id)
      .then((res) => {
        return res;
      });
  }

  public calculate(data): Promise<any> {
    const obj: any = JsonMapper.serialize(data);
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + '/admin_calculatebooking', obj)
      .then((res) => {
        return res;
      });
  }

  public updateRating(data): Promise<any> {
    const obj = data;
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + '/finalrating', obj)
      .then((res) => {
        return res;
      });
  }

  public getSummaryNumber(): Promise<any> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/bookingsummarynumber')
      .then((res) => {
        return res;
      });
  }

  public cancel(id: string): Promise<any> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + '/customercancel/' + id)
      .then((res) => {
        return res;
      });
  }

  public cancelConfirm(id: string, data: TransactionModel): Promise<any> {
    const obj = data;
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.TRANSACTION + '/customercancel/' + id, obj)
      .then((res) => {
        return res;
      });
  }

  public bookOff(data: TransactionModel): Promise<TransactionModel> {
    const obj: any = TransactionModel.toBookOffRequest(data);
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + '/bookoff', obj)
      .then((res) => {
        return JsonMapper.deserialize(TransactionModel, res);
      });
  }

  public updateBooking(data: TransactionModel): Promise<TransactionModel> {
    const obj: any = TransactionModel.toRequest(data);
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.TRANSACTION + `/${data.id}`, obj)
      .then((res) => {
        return JsonMapper.deserialize(TransactionModel, res);
      });
  }

  public changeCar(data: TransactionModel): Promise<TransactionModel> {
    const obj: any = TransactionModel.toRequest(data);
    obj.oldTransactionId = data.id;
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + `/change_car`, obj)
      .then((res) => {
        return JsonMapper.deserialize(TransactionModel, res);
      });
  }

  public getBill(id: string): Promise<TransactionModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/bill/' + id);
  }

  public getContract(id: string): Promise<TransactionModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/contract/' + id);
  }

  public getInvoice(id: string): Promise<TransactionModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/invoiceinvoice/' + id);
  }

  public updateBookingStatus(id: string, bookingStatus: string): Promise<TransactionModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.TRANSACTION + `/admin_status/${id}`, { bookingStatus: bookingStatus })
      .then((res) => {
        return JsonMapper.deserialize(TransactionModel, res);
      });
  }


  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.TRANSACTION + '/' + id)
      .then((res) => {
        return ResponseModel.deleteSuccess();
      });
  }

  public export(filter: any = {}): Promise<any> {
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.downloadFile('GET', `${this.apiUrl}/` + REST_API.TRANSACTION + '/export?' + queryString)
  }

  public exportAutocare(filter: any = {}): Promise<any> {
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.downloadFile('GET', `${this.apiUrl}/` + REST_API.TRANSACTION + '/autocare?' + queryString)
}

  public reportMoney(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {
    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.TRANSACTION + '/money_flow?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(TransactionModel, res.data);
        return res;
      });
  }

  public bookConfirm(data: BookingConfirmModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.TRANSACTION + '/book_confirm', data)
        .then((res) => {
            return ResponseModel.createSuccess(res);
        });
  }
}
