/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class BannerModel extends BaseModel {
  @Json('url')
  public url: string = undefined;

  @Json('text')
  public text: string = undefined;

  @Json('page')
  public page: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('url', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('page', 'required', true, this._t('Vị trí hiển thị không đươc để trống.'));
    
    return this.getRules();
  }
}