/**
 * Created by kiettv on 7/21/16.
 */
import { BaseModel } from "./base.model";
import { Json } from "app/modules";

export class BillExtrasFieldModel extends BaseModel {
    @Json('fieldId')
    public fieldId: string = undefined;

    @Json('fieldName')
    public fieldName: string = undefined;

    @Json('compafieldValuenyExtras')
    public fieldValue: number = undefined;

    @Json('isCarOwnerRelate')
    public isCarOwnerRelate: number = undefined;
}

export default BillExtrasFieldModel;
