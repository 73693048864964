
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { CarModel } from './car.model';

export class MoneyTransactionCarExtrasModel extends BaseModel {

  @Json('name')
  public name: string = undefined;

  @Json('value')
  public value: number = undefined;

  @Json('carId')
  public carId: string = undefined;

  @Json('userId')
  public userId: string = undefined;

  @Json('car')
  public car: CarModel = undefined;

  constructor() {
    super();
  }
}