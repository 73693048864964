import * as momentTz from 'moment-timezone';
import { SESSION, TIME_ZONE, MOMENT_DATE_FORMAT } from '../modules/constants';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
const momentRange = extendMoment(moment);

export class DateHelper {
  constructor() {

  }

  /**
   *
   * @param date
   * @param format
   * @returns {string}
   */
  public static toDbDate(date: any) {
    return momentTz.tz(date, this.getTimezone()).format(MOMENT_DATE_FORMAT.YYYY_MM_DD);
  }

  /**
   *
   * @param date
   * @param format
   * @returns {string}
   */
  public static toFormat(date: any, format: string) {
    const date2 = DateHelper.toISOString(date);
    return momentTz.tz(date2, this.getTimezone()).format(format);
  }

  /**
   * format date use at slot time
   * @param date
   * @param format
   * @returns {any|string}
   */
  public static toFormatDate(date: any, format: string) {
    const date2 = DateHelper.toISOString(date);
    return momentTz(date2).format(format);
  }

  public static getTimezone() {
    let data = null;
    if (sessionStorage.length) {
      const session = sessionStorage;
      if (session[SESSION.TOKEN_KEYWORD]) {
        data = session[SESSION.PROFILE_KEYWORD];
      }
    }
    let timezone = TIME_ZONE.TIME_ZONE_DEFAULT;
    if (data) {
      const dataJson = JSON.parse(data);
      if (dataJson.condoManager && dataJson.condoManager != null) {
        if (dataJson.condoManager.timezone) {
          timezone = dataJson.condoManager.timezone;
        }
      }
    }
    return timezone;
  }

  public static getDayOfWeek(date: any) {
    return new Date(date).getDay();
  }

  public static getTime(date: any) {
    const hour = new Date(date).getHours();
    const minute = new Date(date).getMinutes();
    let r = '';

    if (hour === 0) {
      r = '12 AM';
    } else if (hour > 12) {
      r = (hour - 12) + ' : ' + minute + ' PM';
    } else {
      r = (hour) + ' : ' + minute + ' AM';
    }

    return r;
  }

  /**
   *
   * @param date1
   * @param date2
   * @returns {boolean}
   */
  public static compareDate(date1, date2) {
    const newDate1 = momentTz(this.parseDate(date1));
    const newDate2 = momentTz(this.parseDate(date2));

    if (newDate1.isSameOrAfter(newDate2)) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param date
   * @returns {boolean}
   */
  static isValidDate(date: string) {
    if (this.parseDate(date)) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param date
   * @returns {string}
   */
  static toISOString(date: string) {

    return momentTz(date).toISOString();
  }

  /**
   *
   */
  static addDays(date: string, additionalDays: number) {
    const dateResult = momentTz(date).add(additionalDays, 'd').toISOString();
    return dateResult;
  }

  /**
   *
   * @param date
   * @returns {number}
   */
  public static parseDate(date: string) {
    const parsed = Date.parse(date);

    if (!isNaN(parsed)) {
      return parsed;
    }
    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
  }

  /**
   *
   * @param date1
   * @param date2
   * @returns {boolean}
   */
  static compare2Date(date1, date2) {
    const newDate1 = momentTz(date1);
    const newDate2 = momentTz(date2);

    if (newDate1.isSameOrAfter(newDate2)) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param date (DD-MM-YYYY)
   * @returns timestamp
   */
  public static date2Timestamp(date, next) {
    let myDate = date;
    myDate = myDate.split('-');
    let newDate = (myDate[1]) + '/' + myDate[0] + '/' + myDate[2];
    let month = new Date(newDate).getMonth();
    if (next === true) {
      if (month === 10) {
        myDate[2] = parseInt(myDate[2]) + 1;
        month = 1;
      } else if (month === 11) {
        myDate[2] = parseInt(myDate[2]) + 1;
        month = 2;
      } else {
        month = month + 2;
      }
      newDate = (month) + '/' + myDate[0] + '/' + myDate[2];
    } else {
      if (month === 11) {
        myDate[2] = parseInt(myDate[2]) + 1;
        month = 1;
      } else {
        month = month + 1;
      }
      newDate = (month) + '/' + myDate[0] + '/' + myDate[2];
    }
    return (new Date(newDate).getTime());
  }

  public static getCurrentTime(format) {
    return momentTz.tz(new Date(), this.getTimezone()).format(format);
  }

  public static gePreviousTimeByMonth(format, month) {
    const date = new momentTz().subtract(month, 'months').date(1);
    return momentTz.tz(date, this.getTimezone()).format(format);
  }

  public static convertToTimestamp(date) {
    return (new Date().getTime());
  }

  public static getStartYear() {
    return (new Date(momentTz().startOf('year')).getTime());
  }

  public static getEndYear() {
    return (new Date(momentTz().endOf('year')).getTime());
  }

  public static getRangeDate(startDate: string, endDate: string) {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    const range = momentRange.range(start, end);
    const dates = Array.from(range.by('days'));
    const days = dates.map(m => m.format('YYYY-MM-DD'));
    
    return days;

  }

  public static currentDay() { 
    const date = new Date(); 
    return DateHelper.toFormat(new Date(date), MOMENT_DATE_FORMAT.YYYY_MM_DD);
  } 

  public static getDaysInMonth(month, year) { 
    return new Date(year, month, 0).getDate(); 
  } 

  public static getFirstDayOfMonth() {
    const date = new Date(); 
    return DateHelper.toFormat(new Date(date.getFullYear(), date.getMonth(), 1), MOMENT_DATE_FORMAT.YYYY_MM_DD_H_m);
  }

  public static getLastDayOfMonth() {
    const date = new Date(); 
    const lastDay = DateHelper.toFormat(new Date(date.getFullYear(), date.getMonth(), this.getDaysInMonth(date.getMonth()+1,date.getFullYear())),MOMENT_DATE_FORMAT.YYYY_MM_DD);
    return lastDay + ' 23:59';
  }

  public static getFirstDayOfMonthNoTime() {
    const date = new Date(); 
    return DateHelper.toFormat(new Date(date.getFullYear(), date.getMonth(), 1), MOMENT_DATE_FORMAT.YYYY_MM_DD);
  }

  public static getLastDayOfMonthNoTime() {
    const date = new Date(); 
    return DateHelper.toFormat(new Date(date.getFullYear(), date.getMonth(), this.getDaysInMonth(date.getMonth()+1,date.getFullYear())),MOMENT_DATE_FORMAT.YYYY_MM_DD);
  }
}
