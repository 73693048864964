import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    animations: customAnimations
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    depth: number;
    activeTitle: string;
    activeTitles: string[] = [];
    expanded: boolean;
    nav_collapsed_open = false;
    logoUrl = 'assets/img/logo.png"';
    public config: any = {};
    layoutSub: Subscription;


    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        public translate: TranslateService,
        private configService: ConfigService,
        private layoutService: LayoutService,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
    ) {
        super(router, _route, _meta, _location, _toastr);

        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }

        this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
            options => {
                if (options) {
                    if (options.bgColor) {
                        if (options.bgColor === 'white') {
                            this.logoUrl = 'assets/img/logo-dark.png';
                        }
                        else {
                            this.logoUrl = 'assets/img/logo.png';
                        }
                    }

                    if (options.compactMenu === true) {
                        this.expanded = false;
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = true;
                    }
                    else if (options.compactMenu === false) {
                        this.expanded = true;
                        this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                        this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                        this.nav_collapsed_open = false;
                    }

                }
            });

    }


    ngOnInit() {
        this.config = this.configService.templateConf;
        this.menuItems = ROUTES;



        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/logo-dark.png';
        }
        else {
            this.logoUrl = 'assets/img/logo.png';
        }


    }

    ngAfterViewInit() {

        setTimeout(() => {
            if (this.config.layout.sidebar.collapsed != undefined) {
                if (this.config.layout.sidebar.collapsed === true) {
                    this.expanded = false;
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = true;
                }
                else if (this.config.layout.sidebar.collapsed === false) {
                    this.expanded = true;
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = false;
                }
            }
        }, 0);


    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    toggleSlideInOut() {
        this.expanded = !this.expanded;
    }

    handleToggle(titles) {
        this.activeTitles = titles;
    }

    // NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf("forms/ngx") !== -1)
            this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
    }

    public canDisplay(menuItem: RouteInfo): boolean {
        let isValid = false;
        if (this.profile && this.profile.role) {
            if (!menuItem.privileges && menuItem.privileges && this.profile.role &&
                (menuItem.privileges.indexOf(this.profile.role) !== -1)) {
                isValid = true;
            }
        }
        return isValid;
    }
}
