/**
 * Created by duy thieu on 2020.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';

export class PaymentModel extends BaseModel {
  @Json('paymentType')
  public paymentType: string = undefined;

  @Json('value')
  public value: number = undefined;

  constructor() {
    super();
  }
}