/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';
import { UserModel } from './user.model';
import { TransactionModel } from './transaction.model';
import { MoneyTransactionPeriodModel } from './money_transaction_period.model';
import BillModel from './bill.model';
import { MoneyTransactionCarExtrasModel } from './money_transaction_car_extras.model';

export class NotificationActionModel extends BaseModel {
  @Json('fullAction')
  public fullAction: string = undefined;

  @Json('userId')
  public userId: string = undefined;

  @Json('transactionId')
  public transactionId: string = undefined;

  @Json('moneyTransactionPeriodId')
  public moneyTransactionPeriodId: string = undefined;

  @Json('billId')
  public billId: string = undefined;

  @Json('type')
  public type: string = undefined;

  @Json('user')
  public user: UserModel = undefined;

  @Json('transaction')
  public transaction: TransactionModel = undefined;

  @Json('moneyTransactionPeriod')
  public moneyTransactionPeriod: MoneyTransactionPeriodModel = undefined;

  @Json('bill')
  public bill: BillModel = undefined;

  @Json('customer')
  public customer: UserModel = undefined;

  @Json('carExtras')
  public carExtras: MoneyTransactionCarExtrasModel = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}