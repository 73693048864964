import { ElementRef, Injectable, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    @ViewChild('epltable') epltable: ElementRef;
    templateToExcel:string[][] = [[],[]];

    constructor() { }

    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';

    public exportExcel(jsonData: any[], fileName: string, excelHeaders: string[]): void {
        const ws = XLSX.utils.book_new();
        // set header
        this.templateToExcel = [excelHeaders,[]];
        XLSX.utils.sheet_add_aoa(ws, this.templateToExcel);
        
        // set data
        XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true });

        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, fileName);
    }

    private saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
}