import { ENABLE_STATUS, MOMENT_DATE_FORMAT } from './../modules/constants';
/**
 * Created by phuongho on 10/14/16.
 */
import { ValidateModel } from './validate.model';
import { UtilHelper } from '../helpers/util.helper';
import { Json, JsonConverter } from '../modules';
import * as momentTz from 'moment-timezone';
import * as _ from 'lodash';

class JsonDateConverter implements JsonConverter<momentTz.Moment> {
  public fromJson(data: any): momentTz.Moment {
    let date: momentTz.Moment;

    if (data != null) {
      if (_.isDate(data)) {
        date = momentTz.tz(data, 'UTC').format(MOMENT_DATE_FORMAT.YYYY_MM_DD);
      } else {
        date = momentTz.tz(new Date(data), 'UTC').format(MOMENT_DATE_FORMAT.YYYY_MM_DD);
      }
      return date;
    } else {
      return null;
    }
  }

  public toJson(data: any): any {
    if (data != null) {
      if (_.isDate(data) && data.isValid()) {
        return data.toDate();
      } else {
        return data;
      }
    }
  }
}

export const JsonDate = new JsonDateConverter();

class JsonDateTimeConverter implements JsonConverter<momentTz.Moment> {
  public fromJson(data: any): momentTz.Moment {
    let date: momentTz.Moment;

    if (data != null) {
      if (_.isDate(data)) {
        date = momentTz.tz(data, 'UTC').format(MOMENT_DATE_FORMAT.YYYY_MM_DD);
      } else {
        date = momentTz.tz(new Date(data), 'UTC').format(MOMENT_DATE_FORMAT.YYYY_MM_DD);
      }
      return date;
    } else {
      return null;
    }
  }

  public toJson(data: any): any {
    if (data != null) {
      if (_.isDate(data) && data.isValid()) {
        return data.toDate();
      } else {
        return data;
      }
    }
  }
}

export const JsonDateTime = new JsonDateConverter();

export class BaseModel {
  @Json('id')
  public id: string = undefined;

  @Json('createdDate')
  public createdDate: string = undefined;

  @Json('updatedDate')
  public updatedDate: string = undefined;

  @Json('isEnable')
  public isEnable: boolean = ENABLE_STATUS.YES;

  @Json('isDeleted')
  public isDeleted: boolean = undefined;

  @Json({ name: 'validateRules', ignore: true })
  public validateRules: ValidateModel;

  constructor() {
  }

  /**
   * Validate rule
   * @returns {}
   */

  public initValidateRules(): ValidateModel {
    return this.validateRules.getRules();
  }

  /**
   *
   * @param fieldName
   * @param rule
   * @param value
   * @param message
   */
  public addRule(fieldName: string, rule: string, value: any = true, message: string = '') {
    this.validateRules.addRule(fieldName, rule, value, message);
  }

  /**
   *
   * @param fieldName
   * @param rule
   * @param value
   * @param message
   */
  public removeRule(fieldName: string, rule: string) {
    this.validateRules.removeRule(fieldName, rule);
  }

  /**
   *
   * @param fieldName
   * @param rule
   */
  public removeAllRule() {
    this.validateRules = new ValidateModel();
  }

  /**
   * Get validate rule
   * @returns {ValidateModel}
   */
  public getRules(): ValidateModel {
    return this.validateRules.getRules();
  }

  /**
   * validate form data
   * @param formElementId
   * @param rules
   * @returns {any}
   */
  public validate(formElementId: string, ruleModel?: ValidateModel): boolean {
    const form = jQuery('#' + formElementId);
    this.resetForm(formElementId, ruleModel);

    if (ruleModel != null) {
      form.validate(ruleModel);
    } else {
      form.validate(this.getRules());
    }
    return form.valid();
  }

  /**
   * Reset validate form data
   * @param formElementId
   * @param rules
   */
  public resetForm(formElementId: string, ruleModel?: ValidateModel): any {
    const form = jQuery('#' + formElementId);
    const validation = form.validate();
    let obj: any = this.getRules();

    if (ruleModel != null) {
      obj = ruleModel;
    }

    validation.resetForm();
    validation.settings.rules = obj.rules;
    validation.settings.messages = obj.messages;
    validation.settings.errorPlacement = obj.errorPlacement;
  }


  /**
   * Get Validate Message
   * @param message
   * @returns {string}
   */
  public _t(message: string, params: any = []): string {
    return UtilHelper.translate(message, params);
  }

  public static getBoolean(val: any, defaultVal?: boolean): boolean {
    if (val != null) {
        if (typeof (val) === "string") {
            val = val.toLowerCase();
        }
        switch (val) {
            case true:
            case 1:
            case "yes":
            case "right":
            case "true":
            case "1":
                return true;
            default:
                return false;
        }
    }
    return defaultVal;
}

public static getNumber(val: any, defaultVal: number = undefined): number {
    if (val != null) {
        let num = Number(val);
        return isNaN(val) ? defaultVal : num;
    }
    return defaultVal;
}
}
