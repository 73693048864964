import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfigService } from '@ngx-config/core';
import { SESSION } from 'app/modules';
import { BaseComponent } from 'app/pages/base.component';
import { UserIdentityService, AuthService } from 'app/services';
import { ReduxAction } from '@harmowatch/ngx-redux-core';
import { UserModel } from 'app/models';
import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import ConfigThemeService from '../services/config.service';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    providers: [AuthService]
})
export class NavbarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    currentLang = "en";
    toggleClass = "ft-maximize";
    placement = "bottom-right";
    public isCollapsed = true;
    layoutSub: Subscription;
    @Output()
    toggleHideSidebar = new EventEmitter<Object>();

    public config: any = {};
    public languages: any = [];

    constructor(
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigThemeService,
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
        private setting: ConfigService,
        private authService: AuthService,
    ) {
        super(_router, _route, _meta, _location, _toastr);
        this.languages = this.setting.getSettings('i18n.availableLanguages');

        this.layoutSub = layoutService.changeEmitted$.subscribe(
            direction => {
                const dir = direction.direction;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            });
    }

    ngOnInit() {
        this.config = this.configService.templateConf;
    }

    ngAfterViewInit() {
        if (this.config.layout.dir) {
            setTimeout(() => {
                const dir = this.config.layout.dir;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            }, 0);

        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === "ft-maximize") {
            this.toggleClass = "ft-minimize";
        } else {
            this.toggleClass = "ft-maximize";
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.emitNotiSidebarChange(true);
    }

    toggleSidebar() {
        const appSidebar = document.getElementsByClassName("app-sidebar")[0];
        if (appSidebar.classList.contains("hide-sidebar")) {
            this.toggleHideSidebar.emit(false);
        } else {
            this.toggleHideSidebar.emit(true);
        }
    }
    public logout() {
        try {
            return Promise.resolve()
                .then(() => {
                    return this.authService.logout();
                })
                .then(() => {
                    UserIdentityService.clearCredentials();
                    this.userLogout();
                    this.navigate(['/login']);
                })
                .catch(error => {
                    this.setError(error);
                });
        } catch (error) {
            this.setError(error);
        }
    }

    @ReduxAction()
    public userLogout() {
        return new UserModel;
    }

    private changeLanguage(language: any): any {
        this.translate.use(language.code).subscribe(() => {
            this._meta.setTag('og:locale', language.culture);
            sessionStorage.setItem(SESSION.LANGUAGE_KEYWORD, JSON.stringify(language));
            this.currentLanguage = language;
        });
    }
}