import * as _ from 'lodash';

export class StringHelper {

  /**
   * Parse
   * @param
   * @returns {string}
   */
  static cutString(string, numberChar) {
    const data = string.split(' ');
    let result: string = '';

    for (let i = 0; i <= numberChar; i++) {
      if (data[i]) {
        result += data[i] + (i < numberChar ? ' ' : '');
      }
    }

    return result + '...';
  }

  /**
   *
   * @param str
   * @returns {string}
   */
  static trim(str: string) {
    const lines = str.split('\n');
    let temp = '';
    for (let i = 0; i < lines.length; i++) {
      if (lines[i]) {
        temp += lines[i] + '\n';
      }
    }
    return temp.trim();
  }

}
