/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel, JsonDate } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class PromotionModel extends BaseModel {
  @Json('code')
  public code: string = undefined;

  @Json('value')
  public value: string = undefined;

  @Json('percent')
  public percent: string = undefined;

  @Json('type')
  public type: string = undefined;

  @Json({ name: 'startDate', converter: JsonDate })
  public startDate: string = undefined;

  @Json({ name: 'endDate', converter: JsonDate })
  public endDate: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('code', 'required', true, this._t('Tiêu để không đươc để trống.'));
    this.addRule('type', 'required', true, this._t('Loại giảm giá không đươc để trống.'));
    this.addRule('percent', 'required', true, this._t('Mô tả ngắn không đươc để trống.'));
    this.addRule('value', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('startDate', 'required', true, this._t('Ngày bắt đầu không đươc để trống.'));
    this.addRule('endDate', 'required', true, this._t('Ngày kết thúc không đươc để trống.'));
    
    return this.getRules();
  }
}