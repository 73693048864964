import { Component, ViewEncapsulation, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, forwardRef } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ConfigService } from '@ngx-config/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../pages/base.component';
import { BaseService } from '../../services';
import { MetaService } from '@ngx-meta/core';
import { DEPLAY_AUTO_COMPLETE_FILTER_CHAR, MIN_AUTO_COMPLETE_FILTER_CHAR, MAP_LOCATION_TYPE } from 'app/modules';

export class GoogleMapLocation {
  public address: string = undefined;
  public latitude: string = undefined;
  public longitude: string = undefined;
  public types: string[] = undefined;

  public static toResponse(data: any[]) {
    const ret: GoogleMapLocation[] = [];
    if (data && data.length) {
      data.forEach(item => {
        const obj = new GoogleMapLocation();
        obj.address = item.formatted_address;
        obj.latitude = item.geometry.location.lat;
        obj.longitude = item.geometry.location.lng;
        obj.types = item.types;
        ret.push(obj);
      });
    }
    return ret;
  }
}
@Component({
  selector: 'app-search-location',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './search_location.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchLocationComponent),
      multi: true
    }
  ]
})
export class SearchLocationComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Output() change: EventEmitter<GoogleMapLocation> = new EventEmitter<GoogleMapLocation>();
  @Input() bindLabel: string = 'address';
  @Input() typeToSearchText: string = 'Nhập địa chỉ tìm kiếm';
  @Input() placeholder: string = '';
  @Input() id: string;
  public mapAddress: GoogleMapLocation;

  public locations: any;
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  private proxyUrl = 'https://cors-anywhere.herokuapp.com';
  private url: string = 'https://maps.googleapis.com/maps/api/geocode/json';

  constructor(
    protected route: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    private _config: ConfigService,
    private _baseService: BaseService,
) {
    super(route, _route, _meta, _location, _toastr);
    this.url = `${this.proxyUrl}/${this.url}?key=${this._config.getSettings('googleMap.apiKey')}&language=vi&address=`;
  }

  public ngOnInit() {
    this.mapAddress = new GoogleMapLocation();
  }


  public writeValue(value: any) {
    if (value) {
      this.mapAddress = value;
      if (!this.mapAddress.address) {
        this.mapAddress.latitude = this._config.getSettings('general.latitude');
        this.mapAddress.longitude = this._config.getSettings('general.longitude');
        this.mapAddress.address =  this._config.getSettings('general.address');
        this.onChange(this.mapAddress);
      }
    } else {
      this.mapAddress = new GoogleMapLocation();
      this.mapAddress.latitude = this._config.getSettings('general.latitude');
      this.mapAddress.longitude = this._config.getSettings('general.longitude');
      this.mapAddress.address =  this._config.getSettings('general.address');
      this.onChange(this.mapAddress);
    }
    this.initAutoComplete();
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
  }

  public initAutoComplete() {
    this.locations = concat(
      of(this.mapAddress.address ? [this.mapAddress] : []), // default items
      this.filterInput.pipe(
        debounceTime(DEPLAY_AUTO_COMPLETE_FILTER_CHAR),
        filter(val => val != null && val !== '' && val.length >= MIN_AUTO_COMPLETE_FILTER_CHAR),
        distinctUntilChanged(),
        switchMap(keyword => {
          return this._baseService.http.get(this.url + keyword, this._baseService.headerOptions())
            .then(res => GoogleMapLocation.toResponse(res.body.results))
            .catch((err) => of([]));
        })));
  }

  /**
   *
   * @param data
   */
  public onChange(data: GoogleMapLocation) {
    this.propagateChange(data);
    this.change.emit(data);
    if (!data) {
      this.mapAddress = new GoogleMapLocation();
      this.initAutoComplete();
    }
  }

  public getLocationType(types: string[]) {
    let icon = 'fa-map-marker-alt';

    if (types && types.length) {
      if (types.indexOf(MAP_LOCATION_TYPE.AIRPORT) !== -1) {
        icon = 'fa-plane';
      } else if (types.indexOf(MAP_LOCATION_TYPE.TRAIN_STATION) !== -1) {
        icon = 'fa-train';
      } else if (types.indexOf(MAP_LOCATION_TYPE.HOSPITAL) !== -1) {
        icon = 'fa-ambulance';
      }
    }

    return icon;
  }
}
