import { ROLE, MAX_SIZE_UPLOAD } from '../../modules/constants';
import {
  MIN_AUTO_COMPLETE_FILTER_CHAR,
  DEPLAY_AUTO_COMPLETE_FILTER_CHAR
} from '../../modules/constants';
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild
} from '@angular/core';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat } from 'rxjs';
import { Router, ActivatedRoute, Event } from '@angular/router';
import {
  UserService, MediaService
} from '../../services';
import {
  UserModel, MediaModel, OptionModel
} from '../../models';
import { BaseComponent } from '../../pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ImageHelper } from 'app/helpers';
import { JsonMapper } from 'app/modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-filter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './user_filter.component.html',
  providers: [
    UserService,
    MediaService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterComponent),
      multi: true
    }
  ]
})

export class UserFilterComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() change: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Input() roleId: string = ROLE.CAR_OWNER;
  @Input() placeholder: string;
  public userForm: UserModel;
  public user: UserModel;
  public users: any;
  public media: MediaModel;
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  // tslint:disable-next-line:member-ordering
  public defaultUsers: UserModel[];
  public ROLE = ROLE;
  public userLevels: OptionModel[];

  @ViewChild('customerModal') private customerModal;
  @ViewChild('agencyModal') private agencyModal;

  private userModalRef: NgbModalRef;

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    protected userService: UserService,
    protected mediaService: MediaService,
    private modalService: NgbModal
  ) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.userForm = new UserModel();
    this.user = new UserModel();
    this.userLevels = OptionModel.getListUserLevel();
    this.defaultUsers = [];
    // this.userService.findAllUser(false, { roleId: this.roleId })
    //   .then(res => {
    //     this.defaultUsers = res;
    this.initAutoComplete();
    // });
  }
  ngOnDestroy() {
  }
  writeValue(value: any) {
    if (value) {
      this.user = value;
    } else {
      this.user = new UserModel();
    }
    this.initAutoComplete();

  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
  }

  initAutoComplete() {
    this.users = concat(
      of((this.user.id ? [this.user] : this.defaultUsers)), // default items
      this.filterInput.pipe(
        debounceTime(DEPLAY_AUTO_COMPLETE_FILTER_CHAR),
        filter(val => val != null && val !== '' && val.length >= MIN_AUTO_COMPLETE_FILTER_CHAR),
        distinctUntilChanged(),
        switchMap(keyword => {
          return this.userService.findAllUser(false, { key: keyword, role: this.roleId })
            .catch((err) => of([]));
        })));
  }

  /**
   *
   * @param data
   */
  public onChange(data: UserModel) {
    const value = (data && data.id) ? data.id : '';
    this.propagateChange(value);
    this.change.emit(data);
    if (!data) {
      this.user = new UserModel();
      this.initAutoComplete();
    }
  }

  public onFileSelect($event: any) {
    let message = "";
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const reader: FileReader = new FileReader();
    const self = this;
    if (ImageHelper.isValidImage(file.type)) {
      if (ImageHelper.isValidFileSize(file.size)) {
        reader.onload = (loadEvent: any) => {
          image.src = loadEvent.target.result;
          $('#profileImage').attr('src', loadEvent.target.result);
        };
        reader.readAsDataURL(file);
        image.onload = function () {
          self.media.image = file;
        }
      } else {
        message = self._t(`File quá lớn. Chỉ cho phép {0}MB.`, MAX_SIZE_UPLOAD);
      }
    } else {
      message = self._t('Chỉ cho phép file *.png , *.jpg.');
    }
    if (message) {
      self.setError(message);
    }
  }

  public showModal(isShow: boolean = true) {
    this.userForm = new UserModel();
    if (isShow) {
      switch (this.roleId) {
        case ROLE.CUSTOMER:
          this.userModalRef = this.modalService.open(this.customerModal, { backdrop: 'static', size: 'lg', windowClass: 'agency-modal custom-modal blue' });
          break;
        case ROLE.CAR_OWNER:
          this.userModalRef = this.modalService.open(this.agencyModal, { backdrop: 'static', size: 'lg', windowClass: 'agency-modal custom-modal blue' });
          break;
      }
    } else {
      this.userForm.resetForm('formUserData');
      this.userModalRef.close();
    }
  }

  public save(): any {
    try {
      this.userForm.role = this.roleId;
      if ((this.media && this.media.image) || this.userForm.avatar) {
        this.userForm.removeRule('avatar', 'required');
      }
      if (this.userForm.validate('formUserData')) {
        Promise.resolve()
          .then(() => {
            if (this.media && this.media.image) {
              return this.mediaService.uploadImage(this.media);
            }
          })
          .then(obj => {
            if (obj) {
              this.userForm.avatar = obj.url;
            }
            this.userForm.userName = this.userForm.phone;
            return this.userService.create(this.userForm);
          })
          .then((response) => {
            this.showModal(false);
            this.setSuccess(response.message);
            this.propagateChange(response.value.id);
            this.change.emit(response.value);
          })
          .catch((error) => {
            this.setError(error);
          });
      }

    } catch (error) {
      this.setError(error);
    }
  }
}