/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class NewsModel extends BaseModel {
  @Json('title')
  public title: string = undefined;

  @Json('images')
  public images: string = undefined;

  @Json('shortDescription')
  public shortDescription: string = undefined;

  @Json('fullDescription')
  public fullDescription: string = undefined;

  @Json('slug')
  public slug: string = undefined;

  @Json('type')
  public type: string = undefined;

  @Json('link')
  public link: string = undefined;

  @Json('metaKeyword')
  public metaKeyword: string = undefined;

  @Json('metaDesc')
  public metaDesc: string = undefined;

  @Json('isFeature')
  public isFeature: number = undefined;

  @Json('categoryId')
  public categoryId: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Tiêu để không đươc để trống.'));
    this.addRule('shortDescription', 'required', true, this._t('Mô tả ngắn không đươc để trống.'));
    this.addRule('images', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('priority', 'number', true, this._t('Độ ưu tiên phải là số.'));
    this.addRule('fullDescription', 'ckrequired', true , this._t('Nội dung không đươc để trống.'));
    
    return this.getRules();
  }
}