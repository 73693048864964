/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class ReportCustomerSummaryModel extends BaseModel {
  @Json('totalBillCount')
  public totalBillCount: number = undefined;

  @Json('totalBookingCount')
  public totalBookingCount: number = undefined;

  @Json('totalCustomerReturnCount')
  public totalCustomerReturnCount: number = undefined;

  @Json('totalNewRegister')
  public totalNewRegister: number = undefined;

  public fromTime: string = undefined;
  public toTime: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
  }

  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}