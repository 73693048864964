import { Directive, ElementRef, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { DATEPICKER_FORMAT, MOMENT_DATE_FORMAT } from '../../modules/constants';
import { DateHelper } from 'app/helpers';

@Directive({
  selector: '[datetimesinglepicker]',
})

export class DateTimeSinglePickerDirective implements OnInit {
  private htmlElement: HTMLElement;
  @Input('format') format: string = DATEPICKER_FORMAT.YYYY_MM_DD;
  @Input('timepicker') timepicker: boolean = true;
  @Input('classes') classes: string;
  @Input('position') position: string = 'bottom left';
  @Input('range') range: boolean = true;
  @Input('minDate') minDate: any = new Date();
  @Input('selectedDate') selectedDate: any;
  @Input('multipleDate') multipleDate: boolean = false;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  private dateSelected: any;

  constructor(private el: ElementRef) {
    this.htmlElement = el.nativeElement;
  }

  public ngOnInit(): any {
    jQuery(this.htmlElement).datepicker({
      timepicker: this.timepicker,
      classes: this.classes,
      multipleDatesSeparator: ' - ',
      autoClose: false,
      position: this.position,
      minDate: this.minDate,
      multipleDates: this.multipleDate,
      selectDate: new Date(this.selectedDate),
      language: {
        days: ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
        daysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        daysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        monthsShort: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        today: 'Hôm Nay',
        clear: 'Xoá',
        dateFormat: this.format,
        firstDay: 0,
      },
      onSelect: (formattedDate, date, inst) => {
          this.dateSelected = date;
      },
      onHide: (inst, animationCompleted) => {
        if(animationCompleted == true ){
            this.ngModelChange.emit(this.dateSelected);
            this.change.emit(this.dateSelected);
        }
      }
    });
  }
}

