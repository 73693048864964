/**
 * Created by kiettv on 7/21/16.
 */
import { BaseModel } from "./base.model";
import { Json } from "app/modules";

export class CalculatedRulePriceModel extends BaseModel {
    @Json('name')
    public name: string;

    @Json('price')
    public price: number;

    @Json('priority')
    public priority: number;

    @Json('isIncreasePrice')
    public isIncreasePrice: Boolean;

    @Json('isCarOwnerRelated')
    public isCarOwnerRelated: Boolean;

}

export default CalculatedRulePriceModel;
