/**
 * Created by phuongho on 10/16/16.
 */
import { BaseSearchModel } from './base.search.model';

export class SearchMoneyTransactionModel extends BaseSearchModel {
    public periodId: string = '';
    public userId: string = '';
    public year: any = '';
    public ownerName: any = '';
}
