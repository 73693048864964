import { Injectable } from '@angular/core';
import { Modal } from 'ngx-modialog/plugins/bootstrap';

@Injectable()
export class AppModal {

    constructor(private modal: Modal) {
    }

    /**
     *
     * @param modal
     * @param title
     * @param body
     * @returns {Promise<TResult>|Promise<U>}
     */
    alert(title: string, body: string) {
        const dialogRef = this.modal.alert()
            .title(title)
            .body(body)
            .open();

        return dialogRef.result;
    }

    /**
     *
     * @param modal
     * @param title
     * @param body
     * @returns {Promise<TResult>|Promise<U>}
     */
    confirm(title: string, body: string) {
        const dialogRef = this.modal.confirm()
            .title(title)
            .body(body)
            .open();
        return dialogRef.result;
    }

    /**
     *
     * @param modal
     * @param title
     * @param body
     * @returns {any}
     */
    prompt(title: string, body: string) {
        const dialogRef = this.modal.prompt()
            .title(title)
            .body(body)
            .open();
        return dialogRef.result;
    }
}
