
import { BaseModel, JsonDate } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { ValidateModel } from './validate.model';
import { CalculatePriceModel } from './calculate_price.model';
import { CalculateModel } from './calculate.model';
import { CarModel } from './car.model';
import { CarServiceModel } from './car_service.model';
import BillModel from './bill.model';
import { LocationModel } from './location.model';
import { BookingConfirmModel } from './booking_confirm.model';
export class TransactionModel extends BaseModel {
  @Json('carId')
  public carId: string = undefined;

  @Json('car')
  public car: CarModel = undefined;

  @Json({ name: 'fromDate', converter: JsonDate })
  public fromDate: string = undefined;

  @Json({ name: 'toDate', converter: JsonDate })
  public toDate: string = undefined;

  @Json('desc')
  public desc: string = undefined;

  @Json('bookingStatus')
  public bookingStatus: string = undefined;

  @Json('creatorId')
  public creatorId: string = undefined;

  @Json('renderName')
  public renderName: string = undefined;

  @Json({ name: 'render', clazz: UserModel, omitEmpty: true })
  public render: UserModel = undefined;

  @Json('customerId')
  public customerId: string = undefined;

  @Json({ name: 'customer', clazz: UserModel, omitEmpty: true })
  public customer: UserModel = undefined;

  @Json('promotionCode')
  public promotionCode: string = undefined;

  @Json('paymentType')
  public paymentType: string = undefined;

  @Json('carDeliverDestination')
  public carDeliverDestination: LocationModel = undefined;

  @Json('carReturnDestination')
  public carReturnDestination: LocationModel = undefined;

  @Json('additionCarServices')
  public additionCarServices: CarServiceModel[] = [];

  @Json('transactionNumber')
  public transactionNumber: string = undefined;

  @Json('paymentLink')
  public paymentLink: string = undefined;

  @Json('totalMoney')
  public totalMoney: number = 0;

  @Json('depositMoney')
  public depositMoney: number = 0;

  @Json('remainMoney')
  public remainMoney: number = 0;

  @Json('bookingCalculate')
  public bookingCalculate: CalculatePriceModel = undefined;

  @Json('bookingRequest')
  public bookingRequest: CalculateModel = undefined;

  @Json('bookingProcessRating')
  public bookingProcessRating: string = undefined;

  @Json('introduceRating')
  public introduceRating: string;

  @Json('isPaid')
  public isPaid: boolean = undefined;

  @Json('isSameLocation')
  public isSameLocation: boolean = undefined;

  @Json('confirmCode')
  public confirmCode: string = undefined;

  @Json('oldTransactionId')
  public oldTransactionId: string = undefined;

  @Json('billId')
  public billId: string = undefined;

  @Json('replacementAmount')
  public replacementAmount: number = 0;

  @Json('bill')
  public bill: BillModel = undefined;

  @Json('createdDate')
  public createdDate: string = undefined;

  @Json('confirmId')
  public confirmId: string = undefined;

  @Json('confirm')
  public confirm: BookingConfirmModel = undefined;

  @Json('confirmStatus')
  public confirmStatus: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('customerId', 'required', true, this._t('Khách hàng không đươc để trống.'));
    this.addRule('fromDate', 'required', true, this._t('Thời nhận xe không đươc để trống.'));
    this.addRule('toDate', 'required', true, this._t('Thời trả xe không đươc để trống.'));
    this.addRule('toDate', 'compareDate', "#fromDate", this._t('Thời trả xe phải lớn hơn bằng thời gian nhận xe.'));
    this.addRule('carDeliverDestination', 'required', true, this._t('Địa điểm trả xe không đươc để trống'));
    this.addRule('carReturnDestination', 'required', true, this._t('Địa điểm trả xe không đươc để trống'));
    return this.getRules();
  }
  public static toRequest(data: TransactionModel) {
    const obj: any = {};
    obj.carId = data.carId;
    obj.bookingStatus = data.bookingStatus;
    obj.fromDate = data.fromDate;
    obj.toDate = data.toDate;
    obj.creatorId = data.creatorId;
    obj.customerId = data.customerId;
    obj.promotionCode = data.promotionCode;
    obj.depositMoney = data.depositMoney ? parseInt(data.depositMoney.toString()) : 0;
    obj.replacementAmount = data.replacementAmount ? parseInt(data.replacementAmount.toString()) : 0;
    obj.paymentType = data.paymentType;
    obj.desc = data.desc;
    obj.carDeliverDestination = data.carDeliverDestination;
    obj.carReturnDestination = data.carReturnDestination;
    if (data.additionCarServices && data.additionCarServices.length) {
      obj.additionCarServices = [];
      data.additionCarServices.forEach(item => {
        obj.additionCarServices.push({
          id: item.id,
          quantity: item.quantity
        })
      });
    }
    obj.promotionCode = data.promotionCode;
    return obj;
  }
  public static toBookOffRequest(data: TransactionModel) {
    const obj: any = {};
    obj.carId = data.carId;
    obj.fromDate = data.fromDate;
    obj.toDate = data.toDate;
    return obj;
  }

}