/**
 * Created by phuongho on 10/16/16.
 */
import { BaseSearchModel } from './base.search.model';

export class SearchBillModel extends BaseSearchModel {
    public fromTime: string = '';
    public toTime: string = '';
    public ownerId: string = '';
    public carId: string = '';
    public transactionCreatorId: string = '';
}
