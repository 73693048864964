import { ROLE } from '../../modules/constants';
import {
  MIN_AUTO_COMPLETE_FILTER_CHAR,
  DEPLAY_AUTO_COMPLETE_FILTER_CHAR
} from '../../modules/constants';
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat } from 'rxjs';
import { Router, ActivatedRoute, Event } from '@angular/router';
import {
  CarService
} from '../../services';
import {
  CarModel, SearchCarModel
} from '../../models';
import { BaseComponent } from '../../pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-car-filter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './car_filter.component.html',
  providers: [
    CarService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarFilterComponent),
      multi: true
    }
  ]
})

export class CarFilterComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() change: EventEmitter<CarModel> = new EventEmitter<CarModel>();
  @Input() roleId: string = ROLE.CAR_OWNER;
  @Input() placeholder: string;
  @Input() search: any = {};
  public car: CarModel;
  public cars: any;
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  // tslint:disable-next-line:member-ordering
  public defaultCars: CarModel[];

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    protected _carService: CarService,
  ) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.car = new CarModel();
    this.defaultCars = [];
    // this._carService.findAllCar(false)
    //   .then(res => {
    //     this.defaultCars = res;
    this.initAutoComplete();
    // });
  }
  ngOnDestroy() {
  }
  writeValue(value: any) {
    if (value) {
      this.car = value;
    } else {
      this.car = new CarModel();
    }
    this.initAutoComplete();

  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
  }

  initAutoComplete() {
    this.cars = concat(
      of((this.car.id ? [this.car] : this.defaultCars)), // default items
      this.filterInput.pipe(
        debounceTime(DEPLAY_AUTO_COMPLETE_FILTER_CHAR),
        filter(val => val != null && val !== '' && val.length >= MIN_AUTO_COMPLETE_FILTER_CHAR),
        distinctUntilChanged(),
        switchMap(keyword => {
          this.search.key = keyword;
          this.search.isEnable = 1;
          return this._carService.findAll(false, this.search)
            .then(res => res.data)
            .catch((err) => of([]));
        })));
  }

  /**
   *
   * @param data
   */
  public onChange(data: CarModel) {
    const value = (data && data.id) ? data.id : '';
    this.change.emit(data);
    this.propagateChange(value);
    if (!data) {
      this.car = new CarModel();
      this.initAutoComplete();
    }
  }
}