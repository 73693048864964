/**
 * Created by phuongho on 10/15/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules/mapper/json.decorator';
import { UtilHelper } from 'app/helpers';
import { ROLE, BANNER_TYPE, CAR_SERVICE_TYPE, CAR_ATTRIBUTE_TYPE, PROMOTION_TYPE, CATEGORY_TYPE, REPORT, MOMENT_DATE_FORMAT, TRANSACTION_TYPE, NOTIFICATION_ACTION_TYPE, BUDGET_TYPE, REASON } from 'app/modules';
import * as moment from "moment-timezone";
export class OptionModel extends BaseModel {
  @Json('title')
  public title: any = undefined;

  @Json('keyword')
  public keyword: any = undefined;

  constructor() {
    super();
  }

  /**
   *
   * @param _title
   * @param _keyword
   * @returns {OptionModel}
   */
  public static init(_title: any, _keyword: any): OptionModel {
    const ret = new OptionModel();
    ret.title = UtilHelper.translate(_title);
    ret.keyword = _keyword;

    return ret;
  }

  public static getListRole(): OptionModel[] {

    const ret: OptionModel[] = [];
    ret.push(this.init('System', ROLE.SYSTEM_ADMIN));
    ret.push(this.init('Sub System', ROLE.SUB_SYSTEM_ADMIN));
    ret.push(this.init('Admin', ROLE.ADMIN));
    ret.push(this.init('Admin Booking', ROLE.ADMIN_BOOKING));
    ret.push(this.init('Admin Content', ROLE.ADMIN_CONTENT));
    ret.push(this.init('Car Owner', ROLE.CAR_OWNER));
    ret.push(this.init('Car Cashier', ROLE.CAR_CASHIER));
    ret.push(this.init('Customer', ROLE.CUSTOMER));
    ret.push(this.init('Content', ROLE.CONTENT));

    return ret;
  }

  public static getListRoleMember(): OptionModel[] {

    const ret: OptionModel[] = [];
    ret.push(this.init('System', ROLE.SYSTEM_ADMIN));
    ret.push(this.init('Sub System', ROLE.SUB_SYSTEM_ADMIN));
    ret.push(this.init('Admin Booking', ROLE.ADMIN_BOOKING));
    ret.push(this.init('Admin Content', ROLE.ADMIN_CONTENT));
    ret.push(this.init('Admin', ROLE.ADMIN));
    ret.push(this.init('Car Cashier', ROLE.CAR_CASHIER));
    ret.push(this.init('Content', ROLE.CONTENT));

    return ret;
  }

  public static getListBannerType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Trang chủ', BANNER_TYPE.HOME));
    ret.push(this.init('Danh sách xe', BANNER_TYPE.CAR_LIST));
    ret.push(this.init('Chi tiết xe', BANNER_TYPE.CAR_DETAIL));
    ret.push(this.init('Giới thiệu', BANNER_TYPE.ABOUT_US));
    ret.push(this.init('Liên hệ', BANNER_TYPE.CONTACT_US));
    ret.push(this.init('Blog xe', BANNER_TYPE.BLOG));
    ret.push(this.init('Chi tiết blog xe', BANNER_TYPE.BLOG_DETAIL));
    ret.push(this.init('Bài viết', BANNER_TYPE.ARTICLE));

    return ret;
  }

  public static getCarServiceType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Gói bổ sung', CAR_SERVICE_TYPE.ADDITIONAL));
    ret.push(this.init('Gói bảo hiểm', CAR_SERVICE_TYPE.INSURRANCE));
    ret.push(this.init('Nửa ngày sáng', CAR_SERVICE_TYPE.HALF_DAY_MORNING));
    ret.push(this.init('Nửa ngày chiều', CAR_SERVICE_TYPE.HALF_DAY_AFTERNOON));

    return ret;
  }

  public static getCarAttributeType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Nhập Chữ', CAR_ATTRIBUTE_TYPE.TEXT));
    ret.push(this.init('Nhập Số', CAR_ATTRIBUTE_TYPE.NUMBER));
    ret.push(this.init('Có/Không', CAR_ATTRIBUTE_TYPE.YES_NO));

    return ret;
  }

  public static getPromotionType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Theo số tiền', PROMOTION_TYPE.MONEY));
    ret.push(this.init('Theo %', PROMOTION_TYPE.PERCENT));

    return ret;
  }

  public static getCarSeat(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init(4, 4));
    ret.push(this.init(5, 5));
    ret.push(this.init(7, 7));

    return ret;
  }

  public static getCarVali(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init(5, 5));
    ret.push(this.init(6, 6));
    ret.push(this.init(7, 7));
    ret.push(this.init(8, 8));
    ret.push(this.init(9, 9));
    ret.push(this.init(10, 10));

    return ret;
  }

  public static getFuel(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Xăng', 'gas'));
    ret.push(this.init('Dầu', 'diesel'));

    return ret;
  }

  public static getCarGear(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Số tự động', 'gear_at'));
    ret.push(this.init('Số sàn', 'gear_mt'));

    return ret;
  }

  public static getCarYear(): OptionModel[] {
    const ret: OptionModel[] = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= (currentYear - 10); i--) {
      ret.push(this.init(i, i));
    }

    return ret;
  }

  public static getListCategoryType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Địa điểm nổi bật', CATEGORY_TYPE.POPULAR_PLACE));

    return ret;
  }

  public static getListReportDay(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('7 ngày', moment().add(7, "days").format(MOMENT_DATE_FORMAT.YYYY_MM_DD)));
    ret.push(this.init('30 ngày', moment().add(30, "days").format(MOMENT_DATE_FORMAT.YYYY_MM_DD)));
    ret.push(this.init('3 tháng',  moment().add(3, "months").format(MOMENT_DATE_FORMAT.YYYY_MM_DD)));

    return ret;
  }

  public static getListReportYear(): OptionModel[] {
    const ret: OptionModel[] = [];
    const currentYear = new Date().getFullYear();
    for (let year = 2015; year <= currentYear; year++) {
      ret.push(this.init(year, year));
    }

    return ret;
  }

  public static getListUserLevel(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('A', 'A'));
    ret.push(this.init('B', 'B'));
    ret.push(this.init('C', 'C'));
    ret.push(this.init('D', 'D'));

    return ret;
  }

  public static getBookingStatus(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Mới đặt', TRANSACTION_TYPE.BOOK_TYPE));
    ret.push(this.init('Đang đi', TRANSACTION_TYPE.ON_GOING_TYPE));
    ret.push(this.init('Đã hoàn thành', TRANSACTION_TYPE.COMPLETE));
    ret.push(this.init('Đã huỷ', TRANSACTION_TYPE.CANCEL));
    ret.push(this.init('Off', TRANSACTION_TYPE.OFF_TYPE));

    return ret;
  }

  public static getNotificationActionType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Tất cả', NOTIFICATION_ACTION_TYPE.ALL));
    ret.push(this.init('Hoá đơn', NOTIFICATION_ACTION_TYPE.BILL));
    ret.push(this.init('Phí phát sinh', NOTIFICATION_ACTION_TYPE.CAR_EXTRAS));
    ret.push(this.init('Cắt tiền', NOTIFICATION_ACTION_TYPE.MONEY_TRANSACTION));
    ret.push(this.init('Tạo khách hàng', NOTIFICATION_ACTION_TYPE.CREATE_USER));
    ret.push(this.init('Book xe', NOTIFICATION_ACTION_TYPE.TRANSACTION));

    return ret;
  }

  public static getListPaymentType(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Tiền mặt', BUDGET_TYPE.CASH));
    ret.push(this.init('ATM - Chuyển khoản', BUDGET_TYPE.ATM));
    ret.push(this.init('Credit Card - Thanh toán thẻ', BUDGET_TYPE.CREDIT_CARD));
    ret.push(this.init('VN Pay', BUDGET_TYPE.VN_PAY));

    return ret;
  }

  public static getReason(): OptionModel[] {
    const ret: OptionModel[] = [];

    ret.push(this.init('Tiền phòng', REASON.ROOM));
    ret.push(this.init('Tiền cọc', REASON.DEPOSIT));
    ret.push(this.init('Tiền công nợ', REASON.DEBT));
    ret.push(this.init('Nhập kho sản phẩm', REASON.ITEM_IN));
    ret.push(this.init('Nhập kho đồ dùng', REASON.KIT_IN));

    return ret;
  }
}
