/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class LocationModel extends BaseModel {
  @Json('address')
  public address: string = undefined;

  @Json('latitude')
  public latitude: string = undefined;

  @Json('longitude')
  public longitude: string = undefined;
}