import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, NotificationActionModel, ResponseModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class NotificationActionService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.NOTIFICATION_ACTION + '?' + queryString)
            .then((res) => {
                // res.data = JsonMapper.deserialize(NotificationActionModel, res.data);
                return res;
            });
    }

      /**
     * Get detail
     * @param id
     */
    public findById(id: string): Promise<NotificationActionModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.NOTIFICATION_ACTION}/${id}`)
            .then((res) => {
                return JsonMapper.deserialize(NotificationActionModel, res);
            });
    }

    /**
     * Create
     * @param data
     */
    public create(data: NotificationActionModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.NOTIFICATION_ACTION}`, JsonMapper.serialize(data))
            .then(() => {
                return ResponseModel.createSuccess();
            });
    }
    /**
     * Update
     * @param data
     */

    public update(data: NotificationActionModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/${REST_API.NOTIFICATION_ACTION}/${data.id}`, JsonMapper.serialize(data))
            .then(() => {
                return ResponseModel.updateSuccess();
            });
    }

    public delete(id: string): Promise<ResponseModel> {
        return this.makeHttpDelete(`${this.apiUrl}/${REST_API.NOTIFICATION_ACTION}/${id}`)
            .then(() => {
                return ResponseModel.deleteSuccess();
            });
    }
}