/**
 * Created by phuongho on 10/16/16.
 */
import { BaseSearchModel } from './base.search.model';

export class SearchCarModel extends BaseSearchModel {
    public date: string = '';
    public engine: string = '';
    public type: string = '';
    public seat: string = '';
    public brandId: string = '';
    public fromDate: string = '';
    public toDate: string = '';
    public userId: string = '';
    public transactionFromTime: string = '';
    public transactionToTime: string = '';
    public bookingStatus: string = '';
    public isEnable: any = '' ;
}
