/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class CarAttributeModel extends BaseModel {
  @Json('name')
  public name: string = undefined;

  @Json('value')
  public value: string = undefined;

  @Json('url')
  public url: string = undefined;

  @Json('type')
  public type: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không đươc để trống.'));
    this.addRule('url', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('type', 'required', true, this._t('Loại không đươc để trống.'));

    return this.getRules();
  }
}