import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from './pages/base.component';
import { SESSION } from './modules';
import { ConfigService } from '@ngx-config/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
    subscription: Subscription;

    constructor(
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
        private config: ConfigService,
        private translate: TranslateService,
    ) {
        super(_router, _route, _meta, _location, _toastr);

    }

    public ngOnInit() {
        this.setMetaData();
        this.setupSessionStorage();
        this.subscription = this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    public setMetaData() {
        let defaultLanguage = this.config.getSettings('i18n.defaultLanguage');
        const defaultTitle = this.config.getSettings('seo.defaultPageTitle');
        const defaultDesc = this.config.getSettings('seo.defaultMetaDescription');
        const defaultImage = this.config.getSettings('seo.defaultMetaImage');
        const defaultKeyword = this.config.getSettings('seo.defaultMetaKeyword');
        const currentLanguage = sessionStorage.getItem(SESSION.LANGUAGE_KEYWORD);

        defaultLanguage = currentLanguage ? JSON.parse(currentLanguage) : defaultLanguage;

        // add available languages & set default language
        this.translate.addLangs(this.config.getSettings('i18n.availableLanguages')
            .map((language: any) => language.code));
        this.translate.setDefaultLang(defaultLanguage.code);
        this._meta.setTag('og:locale', defaultLanguage.culture);

        this.setPageTitle(defaultTitle);
        this.setMetaDescription(defaultDesc);
        this.setMetaKeyword(defaultKeyword);
        this.setMetaImage(defaultImage);
        this.setLanguage(defaultLanguage);
    }

    private setLanguage(language: any): any {
        this.translate.use(language.code).subscribe(() => {
            this._meta.setTag('og:locale', language.culture);
        });
    }

    public setupSessionStorage() {
        if (!sessionStorage.length) {
            // Ask other tabs for session storage
            localStorage.setItem('getSessionStorage', Date.now().toString());
        }

        window.addEventListener('storage', function (event) {

            if (event.key === 'getSessionStorage') {
                // Some tab asked for the sessionStorage -> send it

                localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
                localStorage.removeItem('sessionStorage');

            } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
                // sessionStorage is empty -> fill it

                const data = JSON.parse(event.newValue);

                for (let key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        });
    }
}