import { DISCOUNT_TYPE } from './../modules/constants';

/**
 * Created by kiettv on 7/21/16.
 */
import { BaseModel } from './base.model';
import { Json } from 'app/modules';
import { ValidateModel } from './validate.model';
import BillRuleDateApplyModel from './bill_rule_apply.model';
import { BILL_RULE_BY } from './../modules/constants';
import { CarBrandModel } from './car_brand.model';
import { CarBodyStyleModel } from './car_body_style.model';

export class BillRuleModel extends BaseModel {
    @Json('name')
    public name: string = undefined;

    @Json('type')
    public type: string = undefined;

    @Json('startDate')
    public startDate: string = undefined;

    @Json('endDate')
    public endDate: string = undefined;

    @Json('isBonusForCarOwner')
    public isBonusForCarOwner: boolean = undefined;

    @Json('isUnlimitedTime')
    public isUnlimitedTime: boolean = undefined;

    @Json('amount')
    public amount: number = undefined;

    @Json('amount2')
    public amount2: number = undefined;

    @Json('percent')
    public percent: number = undefined;

    @Json('percent2')
    public percent2: number = undefined;

    @Json('affectBrand')
    public affectBrand: CarBrandModel[] = undefined;

    @Json('affectBodyStyle')
    public affectBodyStyle: CarBodyStyleModel[] = undefined;

    @Json('affectCarId')
    public affectCarId: string = undefined;

    @Json('weekLooper')
    public weekLooper: string = undefined;

    @Json('monthLooper')
    public monthLooper: string = undefined;

    @Json('countLooper')
    public countLooper: number = undefined;

    @Json('manualDays')
    public manualDays: string = undefined;

    @Json('ruleByType')
    public ruleByType: string = undefined;

    @Json('ruleMethod')
    public ruleMethod: string = undefined;

    @Json('isIncreasePrice')
    public isIncreasePrice: boolean = undefined;

    @Json('dayIndicatorForLowerPrice')
    public dayIndicatorForLowerPrice: number = undefined;

    @Json('priority')
    public priority: number = undefined;

    @Json('price')
    public price: number = undefined;

    @Json('apply')
    public apply: BillRuleDateApplyModel[] = undefined;

    @Json('discountType')
    public discountType: string = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
    }

    public initValidateRules(): ValidateModel {
        // this.addRule('name', 'required', true, this._t('Tiêu đề không đươc để trống.'));
        // this.addRule('amount', 'required', true, this._t('Giá tiền không đươc để trống.'));
        // this.addRule('percent', 'required', true, this._t('Giá tiền không đươc để trống.'));
        // this.addRule('affectBrand', 'required', true, this._t('Hãy chọn thương hiệu.'));
        // this.addRule('affectBodyStyle', 'required', true, this._t('Hãy chọn dòng xe.'));
        return this.getRules();
    }

    public static toResponse(data: BillRuleModel): BillRuleModel {
        let model = new BillRuleModel();
        model = data;
        model.isBonusForCarOwner = BillRuleModel.getBoolean(data.isBonusForCarOwner);
        model.isUnlimitedTime = BillRuleModel.getBoolean(data.isUnlimitedTime);
        model.isIncreasePrice = BillRuleModel.getBoolean(data.isIncreasePrice);
        model.isBonusForCarOwner = BillRuleModel.getBoolean(data.isBonusForCarOwner);

        model.percent = BillRuleModel.getNumber(data.percent);
        model.amount = BillRuleModel.getNumber(data.amount);

        if (data.ruleByType !== BILL_RULE_BY.MANUAL) {
            model.ruleMethod = BILL_RULE_BY.AUTO;
        } else {
            model.ruleMethod = data.ruleByType;
        }

        if (data.percent && !data.amount) {
            model.discountType = DISCOUNT_TYPE.PERCENT;
        } else if (!data.percent && data.amount) {
            model.discountType = DISCOUNT_TYPE.AMOUNT;
        }
        return model;
    }

}

export default BillRuleModel;
