/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class ReportCarOwnerSummaryModel extends BaseModel {
  @Json('totalCarCount')
  public totalCarCount: number = undefined;

  @Json('totalCarOwner')
  public totalCarOwner: number = undefined;

  @Json('totalNewCarOwner')
  public totalNewCarOwner: number = undefined;

  @Json('totalNewCarOwnerPause')
  public totalNewCarOwnerPause: number = undefined;

  public fromTime: string = undefined;
  public toTime: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
  }

  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}