/**
 * Created by phuongho on 7/23/16.
 */
import { Directive, ElementRef, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Directive({
    selector: '[moneymask]',
})

export class MoneyMaskDirective implements OnInit {
    private htmlElement: HTMLElement;
    @Input() prefix: string = '';
    @Input() suffix: string = '';
    @Input() allowNegative: boolean = false;
    @Input() thousands: string = '.';
    @Input() decimal: string = ',';
    @Input() affixesStay: boolean = false;
    @Input() allowZero: boolean = false;
    @Input() precision: number = 0;
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    public selector: any;

    constructor(private el: ElementRef) {
        this.htmlElement = el.nativeElement;
    }

    public ngOnInit(): any {
        this.selector = jQuery(this.htmlElement);
        this.selector.maskMoney({
            prefix: this.prefix,
            suffix: this.suffix,
            allowNegative: this.allowNegative,
            thousands: this.thousands,
            decimal: this.decimal,
            affixesStay: this.affixesStay,
            allowZero: this.allowZero,
            precision: this.precision,
        });
        this.selector.change(($event) => {
        const value = this.selector.maskMoney('unmasked')[0] * 1000;
        this.ngModelChange.emit(value);
        this.onChange.emit(value)
    });
}
}