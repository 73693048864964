import { ConfigService } from '@ngx-config/core';
import { HTTP_CONNNECTION_TIMEOUT } from '../constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs-compat/add/operator/timeout';
import 'rxjs-compat/add/operator/finally';



@Injectable()
export class TransferHttp {
  constructor(
    private http: HttpClient,
    public config: ConfigService) {
  }

  /**
   * Performs a request with http method.
   * @param uri
   * @param options
   */
  public request(method: string, url: string, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.request(method, url, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `get` http method.
   */
  public get(url: string, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.get(url, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `post` http method.
   */
  public post(url: string, body: any, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.post(url, body, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `put` http method.
   */
  public put(url: string, body: any, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.put(url, body, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `delete` http method.
   */
  public delete(url: string, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.delete(url, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `patch` http method.
   */
  public patch(url: string, body: any, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.patch(url, body.options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `head` http method.
   */
  public head(url: string, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.head(url, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
  /**
   * Performs a request with `options` http method.
   */
  public options(url: string, options?: any): Promise<any> {
    options.observe = 'response';
    return this.http.options(url, options)
      .timeout(HTTP_CONNNECTION_TIMEOUT)
      .toPromise();
  }
}