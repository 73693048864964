import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';

export class BookingConfirmDataModel extends BaseModel {
  @Json('newCustomer')
  public newCustomer: string = undefined;

  @Json('oldCustomer')
  public oldCustomer: string = undefined;

  @Json('companyCustomer')
  public companyCustomer: string = undefined;

  @Json('careerCustomer')
  public careerCustomer: string = undefined;

  @Json('ownerHouse')
  public ownerHouse: string = undefined;

  @Json('addressCompany')
  public addressCompany: string = undefined;

  @Json('apartmentCustomer')
  public apartmentCustomer: string = undefined;

  @Json('apartmentContract')
  public apartmentContract: string = undefined;

  @Json('addressApartment')
  public addressApartment: string = undefined;

  @Json('guaranteeCustomer')
  public guaranteeCustomer: string = undefined;

  @Json('laborContract')
  public laborContract: string = undefined;

  @Json('ratingCustomer')
  public ratingCustomer: string = undefined;

   /** social */

  @Json('oldCustomerZalo')
  public oldCustomerZalo: boolean = undefined;

  @Json('oldCustomerFb')
  public oldCustomerFb: boolean = undefined;

  @Json('oldCustomerGood')
  public oldCustomerGood: boolean = undefined;

  @Json('newCustomerZalo')
  public newCustomerZalo: boolean = undefined;

  @Json('newCustomerFb')
  public newCustomerFb: boolean = undefined;

  @Json('newCustomerGood')
  public newCustomerGood: boolean = undefined;

  @Json('lowPrice')
  public lowPrice: string = undefined;

  @Json('normalPrice')
  public normalPrice: string = undefined;

  @Json('highPrice')
  public highPrice: string = undefined;

}