/**
 * Created by phuongho on 7/12/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class SettingModel extends BaseModel {
    @Json('webservice')
    public webservice: string = undefined;

    @Json('webintro')
    public webintro: string = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
      }
    
      public initValidateRules(): ValidateModel {
        this.addRule('webservice', 'required', true, this._t('Từ khoá không đươc để trống.'));
        this.addRule('webintro', 'required', true, this._t('Giá trị không đươc để trống.'));
        
        return this.getRules();
      }
}
