/**
 * Created by kiettv on 7/21/16.
 */
import { BaseModel } from './base.model';
import { Json } from 'app/modules';
import { CarServiceModel } from './car_service.model';
import { CalculatedRulePriceModel } from './calculated_rule_price';
import BillExtrasFieldModel from './bill_extras_field';
import { PaymentModel } from './payment.model';

export class BillModel extends BaseModel {
    @Json('rentTotal')
    public rentTotal: number = undefined;

    @Json('additionTotal')
    public additionTotal: number = undefined;

    @Json('discount')
    public discount: number = undefined;

    @Json('overKm')
    public overKm: number = undefined;

    @Json('halfDayTotal')
    public halfDayTotal: number = undefined;

    @Json('total')
    public total: number = undefined;

    @Json('ownerTotal')
    public ownerTotal: number = undefined;

    @Json('companyTotal')
    public companyTotal: number = undefined;

    @Json('transactionId')
    public transactionId: string = undefined;

    @Json('carBodyLostTotal')
    public carBodyLostTotal: number = undefined;

    @Json('name')
    public carId: string = undefined;

    @Json('ownerId')
    public ownerId: string = undefined;

    @Json('transaction')
    public transaction: any = undefined;

    @Json('additional')
    public additional: string = undefined;

    @Json('gasOverCost')
    public gasOverCost: number = undefined;

    @Json('shippingCost')
    public shippingCost: number = undefined;

    @Json('revenueShippingTotal')
    public revenueShippingTotal: number = undefined;

    @Json('revenueBookingTotal')
    public revenueBookingTotal: number = undefined;

    @Json('carWashingCost')
    public carWashingCost: number = undefined;

    @Json('overTimeCost')
    public overTimeCost: number = undefined;

    @Json('transactionStatus')
    public transactionStatus: string = undefined;

    @Json('creatorId')
    public creatorId: string = undefined;

    @Json('creatorName')
    public creatorName: string = undefined;

    @Json('paymentType')
    public paymentType: number = undefined;

    @Json('walletValue')
    public walletValue: number = undefined;

    @Json('cashValue')
    public cashValue: number = undefined;

    @Json('userId')
    public userId: string = undefined;

    @Json('moneyRecord')
    public moneyRecord: string = undefined;

    @Json('moneyRecord')
    public carOwnerRelateExtrasTotal: number = 0;

    @Json('moneyRecord')
    public companyExtrasTotal: number = undefined;

    @Json('promotionTotal')
    public promotionTotal: number = undefined;

    @Json('depositTotal')
    public depositTotal: number = undefined;

    @Json('replacementTotal')
    public replacementTotal: number = undefined;

    @Json('carServiceTotal')
    public carServiceTotal: number = undefined;

    @Json('userPayAmount')
    public userPayAmount: number = undefined;

    @Json('discountTotal')
    public discountTotal: number = undefined;

    @Json('oneDayTotal')
    public oneDayTotal: number = undefined;

    @Json('calculatedTotalPrice')
    public calculatedTotalPrice: number = undefined;

    @Json('additionCarServices')
    public additionCarServices: CarServiceModel[] = undefined;

    @Json('calculatedRulePrices')
    public calculatedRulePrices: CalculatedRulePriceModel[] = undefined;

    @Json('carOwnerRelateExtras')
    public carOwnerRelateExtras: BillExtrasFieldModel[] = undefined;

    @Json('companyExtras')
    public companyExtras: BillExtrasFieldModel[] = undefined;

    @Json('extrasTotal')
    public extrasTotal: number = undefined;

    @Json('companyComission')
    public companyComission: number = undefined;

    @Json("payments")
    public payments: PaymentModel[] = undefined;
}

export default BillModel;
