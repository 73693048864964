/**
 * Created by phuongho on 10/14/16.
 */
import { Json } from '../modules';
import { UtilHelper } from 'app/helpers';

export class ResponseModel {
  @Json('title')
  public title: string = undefined;

  @Json('codeReturn')
  public code: number = undefined;

  @Json('message')
  public message: string = undefined;

  @Json('value')
  public value: any = undefined;

  /**
   *
   * @param code
   * @param message
   * @param value
   * @param title
   * @returns {ResponseModel}
   */
  public static init(code, message, value: any = {}, title: string = ''): ResponseModel {
    const ret: ResponseModel = new ResponseModel();
    ret.code = code;
    ret.value = value;
    ret.message = message;
    ret.title = title;

    return ret;
  }

  public static createSuccess(value?: any) {
    return this.init(undefined, UtilHelper.translate('Tạo dữ liệu thành công.'), value);
  }

  public static updateSuccess(value?: any) {
    return this.init(undefined, UtilHelper.translate('Cập nhật dữ liệu thành công.'), value);
  }

  public static deleteSuccess(value?: any) {
    return this.init(undefined, UtilHelper.translate('Xoá dữ liệu thành công.'), value);
  }
}
