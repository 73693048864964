/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';

export class RatingModel extends BaseModel {
  @Json('rating')
  public rating: number = undefined;

  @Json('name')
  public name: string = undefined;

  @Json('comment')
  public comment: string = undefined;

  constructor() {
    super();
  }
}