import { BaseModel } from './base.model';
import { Json } from '../modules';
import { CarServiceModel } from './car_service.model';

export class CalculateModel extends BaseModel {
  @Json('transactionId')
  public transactionId: string = undefined;

  @Json('carId')
  public carId: string = undefined;

  @Json('fromTime')
  public fromTime: string = undefined;

  @Json('toTime')
  public toTime: string = undefined;

  @Json('deliverLatitude')
  public deliverLatitude: string = undefined;

  @Json('deliverLongitude')
  public deliverLongitude: string = undefined;

  @Json('returnLatitude')
  public returnLatitude: string = undefined;

  @Json('returnLongitude')
  public returnLongitude: string = undefined;

  @Json('promotionCode')
  public promotionCode: string = undefined;

  @Json('additionCarServices')
  public additionCarServices: CarServiceModel[] = [];

  @Json('userId')
  public userId: string = undefined;

  constructor() {
    super();
  }
}
