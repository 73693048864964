import { IMAGE_ORIENTATION, MAX_SIZE_UPLOAD } from '../modules/constants';

export class ImageHelper {
  constructor() { }
  /**
   * Get Image
   * @param imageUrl
   * @param width
   * @param height
   * @param mode
   */
  public static getImage(imageUrl: string, width: number, height: number, mode: string) {
    return imageUrl + '?width=' + width + '&height=' + height + '&quality=' + '&mode=' + mode;
  }

  /**
   *
   * @param width
   * @param height
   */
  public static getOrientation(width: number, height: number) {
    return width > height ? IMAGE_ORIENTATION.LANDSCAPE : IMAGE_ORIENTATION.PORTRAIT;
  }

  /**
   * Check is image
   * @param type
   */
  public static isValidImage(type: string) {
    let imageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return imageTypes.indexOf(type) < 0 ? false : true;
  }

  /**
   * check file size
   * @param size
   */
  public static isValidFileSize(imageSize: number) {
    let maxSize = MAX_SIZE_UPLOAD * 1024 * 1024 ;
    return maxSize < imageSize ? false : true;
  }
}
