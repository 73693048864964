import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';
import {
  INPUT_MAXLENGTH,
} from '../modules/constants';

export class RoleModel extends BaseModel {
  @Json('name')
  public name: string = undefined;

  @Json('id')
  public keyword: string = undefined;

  @Json('desc')
  public desc: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();

  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {

    this.addRule('name', 'required', true, this._t('Name can not be blank.'));
    this.addRule('name', 'maxLength', INPUT_MAXLENGTH, this._t(`Maximum {0} characters.`, INPUT_MAXLENGTH));

    this.addRule('desc', 'required', true, this._t('Description can not be blank.'));
    this.addRule('desc', 'maxLength', INPUT_MAXLENGTH, this._t(`Maximum {0} characters.`, INPUT_MAXLENGTH));

    return this.getRules();
  }
}
