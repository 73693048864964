import { RoleModel } from '../models/role.model';
import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, ResponseModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class RoleService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ROLE + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(RoleModel, res.data);
                return res;
            });
    }

    /**
   * edit role 
   * @returns {Promise<T>|Promise<U>}
   */
    public update(data: RoleModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ROLE + '/' + data.id, JsonMapper.serialize(data));
    }

    /**
   * delete user
   * @returns {Promise<T>|Promise<U>}
   */
    public delete(id: string): Promise<ResponseModel> {

        return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.ROLE + '/' + id);
    }
    /**
     * create user
     * @returns {Promise<T>|Promise<U>}
     */
    public create(data: RoleModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ROLE, JsonMapper.serialize(data));
    }
}