import { ROLE, MAX_SIZE_UPLOAD } from '../../modules/constants';
import {
  MIN_AUTO_COMPLETE_FILTER_CHAR,
  DEPLAY_AUTO_COMPLETE_FILTER_CHAR
} from '../../modules/constants';
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild
} from '@angular/core';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UserService, MediaService
} from '../../services';
import {
  UserModel, MediaModel
} from '../../models';
import { BaseComponent } from '../../pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-search',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './user_search.component.html',
  providers: [
    UserService,
    MediaService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSearchComponent),
      multi: true
    }
  ]
})

export class UserSearchComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() change: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Input() roleIds: string;
  @Input() placeholder: string;
  @Input() formName: string;
  @Input() formControlName: FormControl;
  @Input() search: any = {};
  @Input() parentForm: FormGroup;
  public user: UserModel;
  public users: Observable<any>;
  public media: MediaModel;
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  public defaultUsers: UserModel[];
  public ROLE = ROLE;
  public cmndFront;
  public cmndBack;
  public image;

  @ViewChild('customerModal') private customerModal;
  private userModalRef: NgbModalRef;

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    protected userService: UserService,
    protected mediaService: MediaService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.user = new UserModel();
    this.defaultUsers = [];
    this.initAutoComplete();
  }

  ngOnDestroy() {
  }

  writeValue(value: any) {
    if (value) {
      this.user = value;
    } else {
      this.user = new UserModel();
    }
    this.initAutoComplete();

  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
  }

  initAutoComplete() {
    this.users = concat(
      of((this.user.id ? [this.user] : this.defaultUsers)), // default items
      this.filterInput.pipe(
        debounceTime(DEPLAY_AUTO_COMPLETE_FILTER_CHAR),
        filter(val => val != null && val !== '' && val.length >= MIN_AUTO_COMPLETE_FILTER_CHAR),
        distinctUntilChanged(),
        switchMap(keyword => {
          this.search.key = keyword;
          if(this.roleIds){
            this.search.roleIds = this.roleIds;
          }
          return this.userService.findAllUser(false, this.search)
          .then(res => res )
          .catch((err) => of([]));
        })));
  }

  /**
   *
   * @param data
   */
  public onChange(data: UserModel) {
    const value = (data && data.id) ? data.id : '';
    this.propagateChange(value);
    this.change.emit(data);
    if (!data) {
      this.user = new UserModel();
      this.initAutoComplete();
    }
  }

  createForm() {
		this.form = this.fb.group({
      name: [this.user.displayName, Validators.required],
      phone: [this.user.phone, Validators.required],
      cmnd: [this.user.passport],
      password: [this.user.password],
      email: [this.user.email],
      cmndFront: [''],
      cmndBack: [''],
      fileCmndFront:[''],
      fileCmndBack:['']
    });
  }

  resetForm(){
    this.user = new UserModel();
    this.createForm();
    this.cmndFront = '';
    this.cmndBack = '';
  }

  // onFileSelect(event,checkSide) {
  //   if (event.target.files && event.target.files[0]) {
  //     /** api img */
  //     let media = new MediaModel();
  //     Object.values(event.target.files).forEach(file => {
  //       media.image = file;
  //       this.uploadImage(media,checkSide)
  //     });
  //   }
  // }

  uploadImage(media,checkSide) {
    if (media && media.image) {
      return this.mediaService.uploadImage(media)
      .then(res => {
        this.image = '';
        /** preview img */
        if(checkSide){
          this.cmndFront = '';
          this.cmndFront = res
          this.image = res.id;
          this.form.patchValue({
            fileCmndFront: this.image
          });
        }else {
          this.cmndBack = '';
          this.cmndBack = res
          this.image = res.id;
          this.form.patchValue({
            fileCmndBack: this.image
          });
        }
      });
    }
  }

  public showModal(isShow: boolean = true) {
    this.user = new UserModel();
    if (isShow) {
      this.createForm();
      switch (this.roleIds) {
        case ROLE.CUSTOMER:
          this.userModalRef = this.modalService.open(this.customerModal, { 
            backdrop: 'static', 
            size: 'lg', 
            windowClass: 'custom-modal booking-modal z-1200',
            backdropClass: 'z-1200'
          });
          break;
      }
    } else {
      // this.resetForm();
      this.userModalRef.close();
    }
  }

  prepareUser(): UserModel {
    const controls = this.form.controls;

		this.user.displayName = controls.name.value;
		this.user.phone = controls.phone.value;
    this.user.email = controls.email.value;
    this.user.passport = controls.cmnd.value;
    if(controls.fileCmndFront.value){
      this.user.cmndFrontId = controls.fileCmndFront.value;
    }
    if(controls.fileCmndBack.value){
      this.user.cmndBackId  = controls.fileCmndBack.value;
    }
    if(controls.password.value){
      this.user.password = controls.password.value;
    }else {
      this.user.password = controls.phone.value;
    }
    if(this.user.role != ROLE.CUSTOMER){
      this.user.role = ROLE.CUSTOMER;
    }
    
		return this.user;
  }

  public save(): any {
    try {
      if (this.form.valid) {
        let userResult: UserModel = null;
        Promise.resolve()
          .then(() => {
            this.user = this.prepareUser();
            return this.userService.create(this.user);
          })
          .then((response) => {
            this.showModal(false);
            this.setSuccess(response.message);
            this.change.emit(response.value);
            return response.value
          }).then(user=>{
            userResult = user;
            return this.users  = concat(of([user]) );
          
          }).then(()=>{
            this.onChange(userResult);
          })
          .catch((error) => {
            this.setError(error);
          });
      }

    } catch (error) {
      this.setError(error);
    }
  }
}