import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { BookingConfirmDataModel } from './booking_confirm_data.model';

export class BookingConfirmModel extends BaseModel {
  @Json('transactionId')
  public transactionId: string = undefined;

  @Json('confirmAdminId')
  public confirmAdminId: string = undefined;

  @Json('systemConfirmId')
  public systemConfirmId: string = undefined;
  
  @Json('systemConfirmDate')
  public systemConfirmDate: string = undefined;

  @Json('confirmData')
  public confirmData: BookingConfirmDataModel = undefined;

  @Json('isReject')
  public isReject: boolean = undefined;

  @Json('admin')
  public admin: UserModel = undefined;

  @Json('system')
  public system: UserModel = undefined;
}