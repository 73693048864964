import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { BaseComponent } from '../base.component';
import { UserModel } from '../../models';
import { UserIdentityService, AuthService } from 'app/services';
import { ROLE, SESSION } from '../../modules';
import { ConfigService } from '@ngx-config/core';
import { ReduxAction } from '@harmowatch/ngx-redux-core';

@Component({
    selector: 'app-login-page',
    templateUrl: './login.component.html'
})

export class LoginComponent extends BaseComponent implements OnInit {
    private user: UserModel;

    constructor(
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
        private config: ConfigService,
        private authService: AuthService
    ) {
        super(_router, _route, _meta, _location, _toastr);
    }

    public ngOnInit() {
        this.user = new UserModel();
        this.setPageTitle(this.pageTitle);
        if (UserIdentityService.isLoggedIn()) {
            const returnUrl = sessionStorage.getItem(SESSION.PREV_URL_KEYWORD);
            if (returnUrl) {
                this.navigateByUrl(returnUrl);
            } else {
                this.navigate(['/bookings']);
            }
        }

        let defaultLanguage = this.config.getSettings('i18n.defaultLanguage');
        const currentLanguage = sessionStorage.getItem(SESSION.LANGUAGE_KEYWORD);
        defaultLanguage = currentLanguage ? JSON.parse(currentLanguage) : defaultLanguage;
        sessionStorage.setItem(SESSION.LANGUAGE_KEYWORD, JSON.stringify(defaultLanguage));
    }

    public login() {
        try {
            if (this.user.validate('loginForm')) {
                let isValid = false;
                let roleId = '';

                this.authService.login(this.user)
                    .then(response => {
                        if (response && response.role) {
                            roleId = response.role;
                            switch (roleId) {
                                case ROLE.SYSTEM_ADMIN:
                                case ROLE.SUB_SYSTEM_ADMIN:
                                case ROLE.ADMIN_BOOKING:
                                case ROLE.ADMIN_CONTENT:
                                case ROLE.CAR_OWNER:
                                case ROLE.ADMIN:
                                case ROLE.CAR_CASHIER:
                                case ROLE.CONTENT:
                                    isValid = true;
                                    break;

                                default:
                                    isValid = false;
                            }
                        }

                        if (!isValid) {
                            throw Error(this._t('Bạn không có quyền truy cập.'));
                        }

                        UserIdentityService.setCredentials(response);

                        const returnUrl = this._route.snapshot.queryParams.return;
                        let redirectUrl = this._route.snapshot.queryParams.return;

                        switch (roleId) {
                            case ROLE.SYSTEM_ADMIN:
                            case ROLE.SUB_SYSTEM_ADMIN:
                            case ROLE.ADMIN:
                            case ROLE.CAR_OWNER:
                            case ROLE.ADMIN_BOOKING:
                                redirectUrl = '/admin_booking';
                                break;
                            case ROLE.CAR_CASHIER:
                                redirectUrl = '/bookings';
                                break;
                            case ROLE.ADMIN_CONTENT:
                                redirectUrl = '/cars';
                                break;
                            case ROLE.CONTENT:
                                redirectUrl = '/news';
                                break;
                            default:
                                redirectUrl = '/profile';
                        }
                        this.navigateByUrl(returnUrl ? returnUrl : redirectUrl);
                    })
                    .catch(error => {
                        this.setError(error);
                    });
            }
        } catch (error) {
            this.setError(error);
        }
    }

    @ReduxAction()
    public userLogin(user: UserModel) {
        return user;
    }
}
