/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel, JsonDate } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { ValidateModel } from './validate.model';
import { CarHaveAttributeModel } from './car_have_attribute.model';
import { CarAttributeModel } from './car_attribute.model';
import { TransactionModel } from './transaction.model';
import { MoneyTransactionCarExtrasModel } from 'app/models';

export class CarModel extends BaseModel {
  @Json('name')
  public name: string = undefined;

  @Json('avatar')
  public avatar: string = undefined;

  @Json('color')
  public color: string = undefined;

  @Json('slug')
  public slug: string = undefined;

  @Json('desc')
  public desc: string = undefined;

  @Json('seat')
  public seat: number = undefined;

  @Json('carNumber')
  public carNumber: string = undefined;

  @Json('carFuel')
  public carFuel: string = undefined;

  @Json('carPrice')
  public carPrice: string = undefined;

  @Json('carType')
  public carType: string = undefined;

  @Json('ownerId')
  public ownerId: string = undefined;

  @Json('carDate')
  public carDate: string = undefined;

  @Json('percent')
  public percent: number = undefined;

  @Json('landLocation')
  public landLocation: string = undefined;

  @Json('brand')
  public brand: string = undefined;

  @Json('carFreeAttribute')
  public carFreeAttribute: string = undefined;

  @Json('carBestAttribute')
  public carBestAttribute: string = undefined;

  @Json('carValiSpace')
  public carValiSpace: number = undefined;

  @Json('carGear')
  public carGear: string = undefined;

  @Json('bookCount')
  public bookCount: number = 0;

  @Json('cancelCount')
  public cancelCount: number = 0;

  @Json('totalRating')
  public totalRating: number = 0;

  @Json({ name: 'registrationExpired', converter: JsonDate })
  public registrationExpired: string = undefined;

  @Json({ name: 'nearestMaintenance', converter: JsonDate })
  public nearestMaintenance: string = undefined;

  @Json('owner')
  public owner: UserModel = undefined;

  @Json('carAttributeList')
  public carAttributeList: CarAttributeModel[] = undefined;

  @Json('carHaveAttributeList')
  public carHaveAttributeList: CarHaveAttributeModel[] = undefined;

  @Json('transaction')
  public transaction: TransactionModel[] = undefined;

  @Json('moneyTransactionCarExtras')
  public moneyTransactionCarExtras: MoneyTransactionCarExtrasModel[] = undefined;

  @Json('images')
  public images: String = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên xe không đươc để trống.'));
    this.addRule('carType', 'required', true, this._t('Loại xe không đươc để trống.'));
    this.addRule('carNumber', 'required', true, this._t('Biển số xe không đươc để trống.'));
    this.addRule('color', 'required', true, this._t('Màu sắc không đươc để trống.'));
    this.addRule('brand', 'required', true, this._t('Nhãn hiệu không đươc để trống.'));
    this.addRule('carPrice', 'required', true, this._t('Giá tiền không đươc để trống.'));
    // this.addRule('carPrice', 'number', true, this._t('Giá tiền phải là số.'));
    this.addRule('carFreeAttribute', 'ckrequired', true , this._t('Quyền lợi thuê xe không đươc để trống.'));
    this.addRule('carBestAttribute', 'ckrequired', true , this._t('Đặc tính nổi bật không đươc để trống.'));
    this.addRule('desc', 'ckrequired', true , this._t('Mô tả không đươc để trống.'));
    this.addRule('carFuel', 'required', true, this._t('Động cơ xe không đươc để trống.'));
    this.addRule('carDate', 'required', true, this._t('Năm sản xuất không đươc để trống.'));
    this.addRule('avatar', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('seat', 'required', true, this._t('Số chỗ ngồi không đươc để trống.'));
    this.addRule('carGear', 'required', true, this._t('Truyền động không đươc để trống.'));
    this.addRule('seat', 'number', true, this._t('Số chỗ ngồi phải là số.'));
    this.addRule('percent', 'required', true, this._t('Hoa hồng cho chủ xe không đươc để trống.'));
    this.addRule('percent', 'number', true, this._t('Hoa hồng phải là số.'));

    return this.getRules();
  }
}
