import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'cars',
    loadChildren: 'app/pages/cars/car.module#CarModule'
  },
  {
    path: 'news',
    loadChildren: 'app/pages/news/news.module#NewsModule'
  },
  {
    path: 'customers',
    loadChildren: 'app/pages/customers/customer.module#CustomerModule'
  },
  {
    path: 'articles',
    loadChildren: 'app/pages/articles/article.module#ArticleModule'
  },
  {
    path: 'attributes',
    loadChildren: 'app/pages/car_attributes/car_attribute.module#CarAttributeModule'
  },
  {
    path: 'banners',
    loadChildren: 'app/pages/banners/banner.module#BannerModule'
  },
  {
    path: 'brands',
    loadChildren: 'app/pages/car_brands/car_brand.module#CarBrandModule'
  },
  {
    path: 'body_styles',
    loadChildren: 'app/pages/car_body_styles/car_body_style.module#CarBodyStyleModule'
  },
  {
    path: 'agency',
    loadChildren: 'app/pages/agency/agency.module#AgencyModule'
  },
  {
    path: 'members',
    loadChildren: 'app/pages/members/member.module#MemberModule'
  },
  {
    path: 'services',
    loadChildren: 'app/pages/car_services/car_service.module#CarServiceModule'
  },
  {
    path: 'top_deals',
    loadChildren: 'app/pages/top_deals/top_deal.module#TopDealModule'
  },
  {
    path: 'popular_places',
    loadChildren: 'app/pages/popular_places/popular_place.module#PopularPlaceModule'
  },
  {
    path: 'bookings',
    loadChildren: 'app/pages/bookings/booking.module#BookingModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/pages/settings/setting.module#SettingModule'
  },
  {
    path: 'positive_number',
    loadChildren: 'app/pages/positive_number/positive_number.module#PositiveNumberModule'
  },
  {
    path: 'bill_rule',
    loadChildren: 'app/pages/bill_rule/bill_rule.module#BillRuleModule'
  },
  {
    path: 'bills',
    loadChildren: 'app/pages/bills/bill.module#BillModule'
  },
  {
    path: 'promotions',
    loadChildren: 'app/pages/promotions/promotion.module#PromotionModule'
  },
  {
    path: 'categories',
    loadChildren: 'app/pages/categories/category.module#CategoryModule'
  },
  {
    path: 'money_transaction',
    loadChildren: 'app/pages/money_transaction/money_transaction.module#MoneyTransactionModule'
  },
  {
    path: 'bt_agency',
    loadChildren: 'app/pages/bt_agency/bt_agency.module#BTAgencyModule'
  },
  {
    path: 'bt_business',
    loadChildren: 'app/pages/bt_business/bt_business.module#BTBusinessModule'
  },
  {
    path: 'reports',
    loadChildren: 'app/pages/reports/report.module#ReportModule'
  },
  {
    path: 'agency_money_transaction',
    loadChildren: 'app/pages/agency_money_transaction/agency_money_transaction.module#AgencyMoneyTransactionModule'
  },
  {
    path: 'booking_history',
    loadChildren: 'app/pages/booking_history/booking_history.module#BookingHistoryModule'
  },
  {
    path: 'profile',
    loadChildren: 'app/pages/profile/profile.module#ProfileModule'
  },
  {
    path: 'booking_request',
    loadChildren: 'app/pages/booking_request/booking_request.module#BookingRequestModule'
  },
  {
    path: 'admin_booking',
    loadChildren: 'app/pages/admin_bookings/admin_booking.module#AdminBookingModule'
  },
  {
    path: 'admin_booking_reports',
    loadChildren: 'app/pages/admin_booking_reports/admin_booking_report.module#AdminBookingReportModule'
  },
  {
    path: 'approval',
    loadChildren: 'app/pages/approval/approval.module#ApprovalModule'
  },
  {
    path: 'reject-approval',
    loadChildren: 'app/pages/reject-approval/reject-approval.module#RejectApprovalModule'
  },
  {
    path: 'budget',
    loadChildren: 'app/pages/budget/budget.module#BudgetModule'
  },
  {
    path: 'shift',
    loadChildren: 'app/pages/shift/shift.module#ShiftModule'
  },
];