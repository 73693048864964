import { UserModel } from './user.model';
import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';
import BillRuleModel from './bill_rule.model';
import { CarServiceModel } from './car_service.model';

export class CalculatePriceModel extends BaseModel {
  @Json('deliveryPrice')
  public deliveryPrice: string = '';
  
  @Json('depositPrice')
  public depositPrice: string = '';

  @Json('returnPrice')
  public returnPrice: string = '';

  @Json('oneDayPrice')
  public oneDayPrice: string = '';

  @Json('totalRentDay')
  public totalRentDay: string = '';

  @Json('totalRentDayPrice')
  public totalRentDayPrice: string = '';

  @Json('totalPrice')
  public totalPrice: string = '';

  @Json('promotionValue')
  public promotionValue: string = '';
  
  @Json('calculatedRulePrices')
  public calculatedRulePrices: BillRuleModel[] = [];

  @Json('additionCarServices')
  public additionCarServices: CarServiceModel[] = [];

  constructor() {
    super();
  }
}
