/**
 * Created by phuongho on 7/23/16.
 */
import { Directive, ElementRef, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Directive({
    selector: '[inputmask]',
})

export class InputMaskDirective implements OnInit {
    private htmlElement: HTMLElement;
    @Input('mask') mask: string = '#.##0';
    @Input('reverse') reverse: boolean = true;

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    public selector: any;

    constructor(private el: ElementRef) {
        this.htmlElement = el.nativeElement;
    }

    public ngOnInit(): any {
        this.selector = jQuery(this.htmlElement);
        const self = this;
        this.selector.mask(this.mask, {
            reverse: this.reverse,
            onChange: function (cep) {
                const value = self.selector.cleanVal();
                self.ngModelChange.emit(value);
                self.onChange.emit(value);
            }
        });
    }
}