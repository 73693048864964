import { INPUT_PASSWORD_MAXLENGTH } from './../modules/constants';
import { JsonMapper } from '../modules/mapper/json.mapper';
import { BaseModel, JsonDate } from './base.model';
import {
  INPUT_MAXLENGTH,
} from '../modules/constants';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { CarModel } from './car.model';
import { MoneyTransactionModel } from './money_transaction.model';
import BillModel from './bill.model';

export class UserModel extends BaseModel {
  @Json('email')
  public email: string = undefined;

  @Json('oldPassword')
  public oldPassword: string = undefined;

  @Json('newPassword')
  public newPassword: string = undefined;

  @Json('password')
  public password: string = undefined;

  @Json('passwordAgain')
  public passwordAgain: string = undefined;

  @Json('userName')
  public userName: string = undefined;

  @Json('role')
  public role: string = undefined;

  @Json('phone')
  public phone: string = undefined;

  @Json('displayName')
  public displayName: string = undefined;

  @Json('avatar')
  public avatar: string = undefined;

  @Json('fbUserId')
  public fbUserId: string = undefined;

  @Json('fbAccessToken')
  public fbAccessToken: string = undefined;

  @Json('address')
  public address: string = undefined;

  @Json('driverLisence')
  public driverLisence: string = undefined;

  @Json('driverLisenceDay')
  public driverLisenceDay: string = undefined;

  @Json('driverLisencePlace')
  public driverLisencePlace: string = undefined;

  @Json('customerCardId')
  public customerCardId: string = undefined;

  @Json('customerCardDay')
  public customerCardDay: string = undefined;

  @Json('customerCardPlace')
  public customerCardPlace: string = undefined;

  @Json('cardOwner')
  public cardOwner: string = undefined;

  @Json('cardNumber')
  public cardNumber: string = undefined;

  @Json('bankName')
  public bankName: string = undefined;

  @Json('cmndUrlFront')
  public cmndUrlFront: string = undefined;

  @Json('cmndUrlBack')
  public cmndUrlBack: string = undefined;

  @Json('passport')
  public passport: string = undefined;

  @Json('kt3')
  public kt3: string = undefined;

  @Json('businessLisence')
  public businessLisence: string = undefined;

  @Json('dayOfBirth')
  public dayOfBirth: string = undefined;

  @Json('gender')
  public gender: string = undefined;

  @Json('contractNumber')
  public contractNumber: string = undefined;

  @Json('bookingCancelCount')
  public bookingCancelCount: number = undefined;
  
  @Json('bookingSuccessCount')
  public bookingSuccessCount: number = undefined;

  @Json('isCarOwnerDeactive')
  public isCarOwnerDeactive: boolean = undefined;

  @Json('totalCarExtras')
  public totalCarExtras: number = undefined;

  @Json('totalBillForCompany')
  public totalBillForCompany: number = undefined;

  @Json('totalBillForOwner')
  public totalBillForOwner: number = undefined;

  @Json('pin')
  public pin: any = undefined;

  @Json('level')
  public level: string = undefined;

  @Json('totalCompanyComission')
  public totalCompanyComission: number = undefined;

  @Json('previousMoneyTransaction')
  public previousMoneyTransaction: any = undefined;

  @Json({ name: 'contractStartDate', converter: JsonDate })
  public contractStartDate: string = undefined;

  @Json({ name: 'contractEndDate', converter: JsonDate })
  public contractEndDate: string = undefined;

  @Json('cars')
  public cars: CarModel[] = undefined;

  @Json('moneyTransaction')
  public moneyTransaction: MoneyTransactionModel[] = undefined;

  @Json('bill')
  public bill: BillModel[] = undefined;

  @Json('creator')
  public creator: UserModel = undefined;

  @Json('isOldCustomer')
  public isOldCustomer: boolean = undefined;

  @Json('cmndFrontId')
  public cmndFrontId: string = undefined

  @Json('cmndBackId')
  public cmndBackId: string = undefined

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('userName', 'required', true, this._t('Tên đăng nhập không đươc để trống.'));

    this.addRule('displayName', 'required', true, this._t('Họ và tên không đươc để trống.'));

    this.addRule('phone', 'required', true, this._t('Số điên thoại không đươc để trống.'));
    this.addRule('phone', 'number', true, this._t('Số điện thoaị phải là số.'));
    this.addRule('role', 'required', true, this._t('Nhóm người dùng không đươc để trống.'));

    this.addRule('email', 'required', true, this._t('Email không đươc để trống.'));
    this.addRule('email', 'formatEmail', true, this._t('Định dạng email không đúng.'));
    this.addRule('email', 'maxLength', INPUT_MAXLENGTH, this._t(`Maximum {0} characters.`, INPUT_MAXLENGTH));

    this.addRule('pin', 'required', true, this._t('Mã xác nhận không đươc để trống.'));
    this.addRule('oldPassword', 'required', true, this._t('Mật khẩu cũ không đươc để trống.'));
    this.addRule('newPassword', 'required', true, this._t('Mật khẩu mới không đươc để trống.'));
    this.addRule('password', 'required', true, this._t('Mật khẩu không đươc để trống.'));
    this.addRule('passwordAgain', 'equalTo', '#password', this._t('Mật khẩu xác nhận không đúng.'));
    this.addRule('passwordConfirm', 'equalTo', '#newPassword', this._t('Mật khẩu xác nhận không đúng.'));


    return this.getRules();
  }

  /**
   *
   * @param data
   * @returns {UserModel}
   */
  public static toProfileModel(data: UserModel): UserModel {
    let model = JsonMapper.deserialize(UserModel, data);
    delete model.validateRules; // remove object validate rules
    return model;
  }

}