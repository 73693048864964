/**
 * Created by phuongho on 10/16/16.
 */
import { BaseSearchModel } from './base.search.model';

export class SearchNotificationActionModel extends BaseSearchModel {
    public fromTime: string = '';
    public toTime: string = '';
    public type: string = '';
}
