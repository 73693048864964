export const setting = {
    rest: {
        apiUrl: "https://rest.bongtrip.vn",
        mediaUrl: "https://rest.bongtrip.vn"
    },
    system: {
        applicationName: "APP_NAME",
        applicationUrl: ""
    },
    seo: {
        defaultPageTitle: "DEFAULT_TITLE",
        pageTitlePositioning: 10,
        pageTitleSeparator: " | ",
        defaultMetaDescription: "DEFAULT_META_DESCRIPTION",
        defaultMetaKeyword: "DEFAULT_META_KEYWORD",
        defaultMetaImage: "DEFAULT_META_IMAGE"
    },
    i18n: {
        defaultLanguage: {
            code: "vi",
            name: "Vietnamese",
            culture: "vi-VN",
            img: "assets/i18n/flags/vi.png"
        },
        availableLanguages: [
            {
                code: "en",
                name: "English",
                culture: "en-US",
                img: "assets/i18n/flags/en.png"
            },
            {
                code: "vi",
                name: "Vietnamese",
                culture: "vi-VN",
                img: "assets/i18n/flags/vi.png"
            }
        ],
        autoDetectLanguage: true,
        useLocalizedRoutes: true
    },
    social: {
        facebookAppId: "1929179257393906"
    },
    googleMap: {
        apiKey: "AIzaSyCsiAtjeWhUeb0YD8W4y4F3vggOQYcO_yU",
        latitude: 10.740280,
        longitude: 106.735930,
        zoom: 5
    },
    general: {
        hotline: "1900 66630",
        contactPhone: "+84 977 886 518",
        contactEmail: "sale@bongtrip.com.vn",
        address: "99 Nguyễn Văn Quỳ, Phường Phú Thuận, Quận 7, Tp. Hồ Chí Minh, VN",
        workingTime: "- Thứ Hai đến Thứ Bảy: 9h-22h<br>- Chủ nhật & Ngày lễ: 9h-20h",
        latitude: 10.740280,
        longitude: 106.735930,
    }   
};