import { BaseModel } from './base.model';
import { Json } from '../modules';

export class ReasonModel extends BaseModel {
  @Json('name')
  public name: string = undefined;

  constructor() {
    super();
  }
}