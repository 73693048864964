import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/operator/catch';
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/observable/throw';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private spinner: NgxSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoading();

    return next.handle(req)
      .do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.hideLoading();
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.hideLoading();
        }
      })
      .catch(response => {
        if (response.error) {
          response.message = response.error.message;
        }
        return Observable.throw(response);
      });
  }

  public showLoading() {
    this.spinner.show();
  }

  public hideLoading() {
    this.spinner.hide();
  }
}