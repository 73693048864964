import { ReduxReducer, ReduxActionWithPayload } from '@harmowatch/ngx-redux-core';
import { UserModel } from '../../models';
import { AccountState } from './account.state.provider';
import { LoginComponent } from '../login/login.component';

export class AccountReducer {
    @ReduxReducer(LoginComponent.prototype.userLogin)
    userLogin(state: AccountState, action: ReduxActionWithPayload<UserModel>): AccountState {
        return {
            ...state,
            profile: action.payload,
        };
    }
    // @ReduxReducer(HeaderComponent.prototype.userLogout)
    // userLogout(state: AccountState, action: ReduxActionWithPayload<UserModel>): AccountState {
    //     return {
    //         ...state,
    //         profile: action.payload,
    //     };
    // }
    // @ReduxReducer(HeaderComponent.prototype.getUserProfile)
    // getProfile(state: AccountState, action: ReduxActionWithPayload<UserModel>): AccountState {
    //     return {
    //         ...state,
    //         profile: action.payload,
    //     };
    // }
}