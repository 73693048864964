

/**
 * Created by kiettv on 7/21/16.
 */
import { BaseModel } from './base.model';
import { Json } from 'app/modules';


export class BillRuleDateApplyModel extends BaseModel {
    @Json('billRuleId')
    public billRuleId: string = undefined;

    @Json('date')
    public date: string = undefined;
}

export default BillRuleDateApplyModel;
