import { BaseSearchModel } from './base.search.model';

export class SearchPaymentModel extends BaseSearchModel {
    public isIn: boolean;
    public isPaid: boolean;
    public paymentType: string;
    public fromTime: string = '';
    public toTime: string = '';
    public date: string = '';
}
