/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class CarHaveAttributeModel extends BaseModel {
  @Json('attributeId')
  public attributeId: string = undefined;

  @Json('attributeValue')
  public attributeValue: any = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {

    return this.getRules();
  }
}