/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { CarModel } from './car.model';

export class BookingRequestModel extends BaseModel {
  @Json('requestCarId')
  public requestCarId: string = undefined;

  @Json('requestCarServices')
  public requestCarServices: string = undefined;

  @Json('customerName')
  public customerName: string = undefined;

  @Json('customerPhone')
  public customerPhone: string = undefined;

  @Json('fromDate')
  public fromDate: string = undefined;

  @Json('toDate')
  public toDate: string = undefined;

  @Json('isDone')
  public isDone: string = undefined;

  @Json('car')
  public car: CarModel = undefined;
}