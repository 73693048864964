import { SESSION } from '../modules/constants';
import { UserModel, TokenModel } from '../models';

export class UserIdentityService {
  constructor() {

  }

  /**
   * Set Token
   * @param data
   */
  public static setCredentials(data: TokenModel) {
    if (data.token) {
      this.setToken(data.token);
    }

    if (data.userInfo instanceof UserModel) {
      this.setProfile(data.userInfo);
    }
  }

  /**
   * Set User Profile
   * @param token
   */
  public static setToken(token: string = '') {
    sessionStorage.setItem(SESSION.TOKEN_KEYWORD, token);
  }

  /**
   * Set User Profile
   * @param data
   */
  public static setProfile(data: UserModel) {
    if (data instanceof UserModel) {
      let obj = UserModel.toProfileModel(data);
      sessionStorage.setItem(SESSION.PROFILE_KEYWORD, JSON.stringify(obj));
    }
  }

  /**
   *
   * @returns {any}
   */
  public static getProfile() {
    if (sessionStorage.length) {
      let profile = sessionStorage.getItem(SESSION.PROFILE_KEYWORD);
      if (profile) {
        return JSON.parse(profile);
      }
    }
    return {};
  }

  /**
   * Clear login data
   */
  public static clearCredentials() {
    sessionStorage.clear();
  }


  /**
   *
   * @returns {boolean}
   */
  public static isLoggedIn() {
    return this.getToken() ? true : false;
  }

  /**
   *
   * @returns {any}
   */
  public static getToken() {
    if (sessionStorage.length) {
      let token = sessionStorage.getItem(SESSION.TOKEN_KEYWORD);
      if (token) {
        return token;
      }
    }
    return '';
  }
}