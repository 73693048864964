import { MoneyTransactionModel } from './money_transaction.model';
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';

export class MoneyTransactionPeriodModel extends BaseModel {

  @Json('name')
  public name: string = undefined;

  @Json('previousDate')
  public previousDate: string = undefined;

  @Json('moneyTransaction')
  public moneyTransaction: MoneyTransactionModel[] = undefined;

  @Json('companyTotal')
  public companyTotal: number = undefined;

  @Json('extrasTotal')
  public extrasTotal: number = undefined;

  @Json('total')
  public total: number = undefined;

  constructor() {
    super();
  }
}