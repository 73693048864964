import { RouteInfo } from './sidebar.metadata';
import { ROLE } from 'app/modules';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/bookings',
        title: 'Lịch xe',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN, ROLE.CAR_OWNER, ROLE.CAR_CASHIER]
    },
    {
        path: '/admin_booking',
        title: 'Admin booking',
        icon: 'ft-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.ADMIN_BOOKING]
    },
    {
        path: '#',
        title: 'Quản lý đặt xe',
        icon: 'ft-bell',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN],
        submenu: [
            {
                path: '/booking_request',
                title: 'Đặt xe Online',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            },
            {
                path: '/approval',
                title: 'Danh sách booking',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
            // {
            //     path: '/complete-approval',
            //     title: 'Danh sách đã phê duyệt',
            //     icon: '',
            //     class: '',
            //     badge: '',
            //     badgeClass: '',
            //     isExternalLink: false,
            //     submenu: [],
            //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            // },
            {
                path: '/reject-approval',
                title: 'Huỷ phê duyệt',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
        ]
    },
    {
        path: '/bt_agency',
        title: 'BT - Đại lý',
        icon: 'ft-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
    },
    {
        path: '/bt_business',
        title: 'BT - Kinh doanh',
        icon: 'ft-pie-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
    },
    {
        path: '#',
        title: 'Thống kê, báo cáo',
        icon: 'fa fa-rocket',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN],
        submenu: [
            {
                path: '/reports/business',
                title: 'Báo cáo kinh doanh',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
            {
                path: '/reports/money',
                title: 'Báo cáo dòng tiền',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
            {
                path: '/reports/activity',
                title: 'Nhật ký hoạt động',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
        ]
    },
    {
        path: '#',
        title: 'Quản lý thu chi',
        icon: 'fa fa-usd',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN],
        submenu: [
            {
                path: '/budget/budget-in',
                title: 'Quản lý phiếu thu',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            },
            {
                path: '/budget/budget-out',
                title: 'Quản lý phiếu chi',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            },
            // {
            //     path: '/budget/debt',
            //     title: 'Quản lý công nợ',
            //     icon: '',
            //     class: '',
            //     badge: '',
            //     badgeClass: '',
            //     isExternalLink: false,
            //     submenu: [],
            //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            // },
            {
                path: '/budget/reason',
                title: 'Lý do thu chi',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            },
        ],
    },
    {
        path: '#',
        title: 'Sổ tiền mặt',
        icon: 'fa fa-clock-o',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN],
        submenu: [
            {
                path: '/shift/history',
                title: 'Nhật ký sổ tiền mặt',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
            },
        ],
    },
    {
        path: '/admin_booking_reports/business',
        title: 'Báo cáo kinh doanh',
        icon: 'fa fa-rocket',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.ADMIN_BOOKING]
    },
    {
        path: '/booking_history',
        title: 'Lịch sử thuê xe',
        icon: 'ft-clock',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.CAR_OWNER]
    },
    {
        path: '#',
        title: 'Quản lý xe',
        icon: 'fa fa-car',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT],
        submenu:
            [
                {
                    path: '/cars',
                    title: 'Danh sách xe',
                    icon: '',
                    class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [],
                    privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT, ROLE.ADMIN_CONTENT]
                },
                {
                    path: '/brands',
                    title: 'Thương hiệu',
                    icon: '', class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [],
                    privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
                },
                {
                    path: '/body_styles',
                    title: 'Body Styles',
                    icon: '',
                    class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [],
                    privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
                },
                {
                    path: '/attributes',
                    title: 'Thuộc tính',
                    icon: '', class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [],
                    privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
                },
                {
                    path: '/services',
                    title: 'Dịch vụ',
                    icon: '', class: '',
                    badge: '',
                    badgeClass: '',
                    isExternalLink: false,
                    submenu: [],
                    privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
                },
            ],
    },
    {
        path: '#',
        title: 'Quản lý nội dung',
        icon: 'icon-layers',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.CONTENT, ROLE.ADMIN_CONTENT],
        submenu: [
            {
                path: '/categories',
                title: 'Danh Mục',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/promotions',
                title: 'Mã khuyến mãi',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN]
            },
            {
                path: '/articles',
                title: 'Bài viết',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/news',
                title: 'Blog xe',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.CONTENT, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/top_deals',
                title: 'Top Deals',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.CONTENT, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/popular_places',
                title: 'Địa điểm nổi bật',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.CONTENT, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/banners',
                title: 'Banners',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
            },
            {
                path: '/positive_number',
                title: 'Số liệu thống kê',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
            },
        ]
    },
    {
        path: '/agency_money_transaction',
        title: 'Chốt sô dư trong kỳ',
        icon: 'fa fa-calendar',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.CAR_OWNER]
    },
    {
        path: '/customers',
        title: 'Quản lý khách hàng',
        icon: 'ft-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN]
    },
    {
        path: '#',
        title: 'Setup Bill and Rule',
        icon: 'ft-filter',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN],
        submenu: [
            {
                path: '/bill_rule',
                title: 'Setup Rule',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN]
            },
            {
                path: '/bills',
                title: 'Setup Bill',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN]
            },
        ],
    },
    {
        path: '#',
        title: 'Quản lý đại lý',
        icon: 'fa fa-home',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT],
        submenu: [
            {
                path: '/money_transaction',
                title: 'Lịch sử cắt tiền',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN]
            },
            {
                path: '/agency',
                title: 'Danh sách đại lý',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
            },
        ],
    },
    {
        path: '/members',
        title: 'Quản lý thành viên',
        icon: 'ft-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN]
    },
    {
        path: '/settings',
        title: 'Cài đặt',
        icon: 'ft-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.SUB_SYSTEM_ADMIN, ROLE.ADMIN_CONTENT]
    },
    {
        path: '/profile',
        title: 'Thông tin đại lý',
        icon: 'ft-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.CAR_OWNER]
    }
];
