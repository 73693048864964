/**
 * Created by duy thieu on 2020.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';

export class PaymentCheckModel extends BaseModel {
  @Json('isIn')
  public isIn: number = undefined;

  @Json('isPaid')
  public isPaid: boolean = undefined;

  @Json('paymentCode')
  public paymentCode: string = undefined;

  @Json('paymentType')
  public paymentType: string = undefined;

  @Json('des')
  public des: string = undefined;

  @Json('billId')
  public billId: string = undefined;

  @Json('creatorId')
  public creatorId: string = undefined;

  @Json('userId')
  public userId: string = undefined;

  @Json('address')
  public address: string = undefined;

  @Json('phone')
  public phone: string = undefined;

  @Json('reason')
  public reason: string = undefined;
  
  @Json('paperDate')
  public paperDate: string = undefined;

  @Json('totalMoney')
  public totalMoney: number = 0;

  @Json("payTotal")
  public payTotal: number = 0;

  @Json("customer")
  public customer: UserModel = undefined;

  @Json("creator")
  public creator: UserModel = undefined;

  constructor() {
    super();
  }
}