import { Routes, RouterModule } from '@angular/router';

export const CONTENT_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: 'app/pages/login/login.module#LoginModule'
  },
  {
    path: 'forgot-password',
    loadChildren: 'app/pages/forgot-password/forgot-password.module#ForgotPasswordModule'
  },
];