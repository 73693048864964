/**
 * Created by phuongho on 10/16/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class CarServiceModel extends BaseModel {
  @Json('url')
  public url: string = undefined;

  @Json('name')
  public name: string = undefined;

  @Json('type')
  public type: string = undefined;

  @Json('desc')
  public desc: string = undefined;

  @Json('price')
  public price: number = undefined;

  @Json('percent')
  public percent: number = undefined;

  @Json('maxQuantity')
  public maxQuantity: number = undefined;

  @Json('quantity')
  public quantity: number = undefined;

  @Json('originalQuantity')
  public originalQuantity: number = undefined;

  @Json('isMultiplyByDate')
  public isMultiplyByDate: boolean = undefined;

  @Json('totalPrice')
  public totalPrice: number = undefined;

  @Json('dayIndicator')
  public dayIndicator: boolean = undefined;

  @Json('price2')
  public price2: number = undefined;

  @Json('percent2')
  public percent2: number = undefined;

  @Json('isDisplayForClient')
  public isDisplayForClient: boolean = undefined;

  @Json('isCarOwnerRelated')
  public isCarOwnerRelated: boolean = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không đươc để trống.'));
    this.addRule('desc', 'required', true, this._t('Mô tả không đươc để trống.'));
    this.addRule('price', 'required', true, this._t('Giá không đươc để trống.'));
    this.addRule('price', 'number', true, this._t('Giá phải là số.'));
    this.addRule('price2', 'required', true, this._t('Giá không đươc để trống.'));
    this.addRule('price2', 'number', true, this._t('Giá phải là số.'));
    this.addRule('percent', 'required', true, this._t('Phần trăm không đươc để trống.'));
    this.addRule('percent', 'number', true, this._t('Phần trăm phải là số.'));
    this.addRule('percent2', 'required', true, this._t('Phần trăm không đươc để trống.'));
    this.addRule('percent2', 'number', true, this._t('Phần trăm phải là số.'));
    this.addRule('url', 'required', true, this._t('Hình ảnh không đươc để trống.'));
    this.addRule('type', 'required', true, this._t('Loại không đươc để trống.'));
    this.addRule('maxQuantity', 'required', true, this._t('Số lượng tối đa không đươc để trống.'));
    this.addRule('maxQuantity', 'number', true, this._t('Số lượng tối đa phải là số.'));

    return this.getRules();
  }
}