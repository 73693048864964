import { MoneyTransactionPeriodModel } from './money_transaction_period.model';
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import BillModel from './bill.model';

export class MoneyTransactionModel extends BaseModel {

  @Json('billTotal')
  public billTotal: number = 0;

  @Json('description')
  public description: string = undefined;

  @Json('desc')
  public desc: string = undefined;

  @Json('userId')
  public userId: string = undefined;

  @Json('periodId')
  public periodId: string = undefined;

  @Json('user')
  public user: UserModel = undefined;

  @Json('extrasMoney')
  public extrasMoney: number = 0;

  @Json('totalMoney')
  public totalMoney: number = 0;

  @Json('carActive')
  public carActive: number = 0;

  @Json('isPaid')
  public isPaid: boolean = undefined;

  @Json('companyReceivedMoney')
  public companyReceivedMoney: number = 0;

  @Json('previousMoneyTransaction')
  public previousMoneyTransaction: MoneyTransactionModel = undefined;

  @Json('moneyTransactionPeriod')
  public moneyTransactionPeriod: MoneyTransactionPeriodModel = undefined;

  @Json('bills')
  public bills: BillModel[] = undefined;

  constructor() {
    super();
  }
}