/**
 * Created by phuongho on 10/14/16.
 */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const AUTHORIZATION = {
  TYPE: 'Authorization',
  METHOD: 'Bearer'
};

export const COOKIE = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language'
};

export const SESSION = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language',
  NEXT_URL_KEYWORD: 'nextUrl',
  PREV_URL_KEYWORD: 'prevUrl'
};

export const ERROR_CODE = {
  AUTHENTICATION: {
    GENERIC: 1100,
    VIOLATE_RFC6750: 1101,
    TOKEN_NOT_FOUND_CODE: 1102,
    NOT_AUTHORIZED_CODE: 1103,
    NOT_PERMISSION_ACCESS_CODE: 1104,
    WRONG_USER_OR_PASSWORD_CODE: 1105,
    INVALID_ACCESS_TOKEN_CODE: 1106,
    TOKEN_EXPIRED_CODE: 1107,
  },
  UNKNOWN: {
    MAINTENANCE_PERIOD: 3,
  }
};
export const ROLE = {
  SYSTEM_ADMIN: 'System',
  SUB_SYSTEM_ADMIN: 'SubSystem',
  ADMIN: 'Admin',
  ADMIN_BOOKING: 'AdminBooking',
  ADMIN_CONTENT: 'AdminContent',
  CAR_OWNER: 'CarOwner',
  CUSTOMER: 'Customer',
  CAR_CASHIER: 'CarCashier',
  CONTENT: 'Content',
};

export const PAGINATION = {
  MAX_SIZE: 5,
  ITEMS_PER_PAGE: 100,
  PAGE_ITEM_SIZE: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
  BT_BUSINESS_ITEMS_PER_PAGE: 10,
  BT_AGENCY_ITEMS_PER_PAGE: 10,
};

export const HEADERS = {
  TOTAL_ITEMS: 'Total',
  ITEM_PER_PAGE: 'Item-Per-Page',
  CONTENT_DISPOSITION: 'Content-Disposition',
  CONTENT_TYPE: 'Content-Type',
  STATUS_CODE_SUCCESS: 200,
  DEVICE_ID: 'device-id',
  REGISTRAR_ID: 'registrar-id',
  USER_AGENT: 'userAgent',
  DEVICE_OS: 'device-os',
  APP_VERSION: 'app-version',
  LANGUAGE: 'language',
  TIMEZONE: 'timezone',
};

export const HTTP = {
  METHOD: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  },
  CONTENT_TYPE: {
    JSON: 'application/json; charset=utf-8'
  },
  HEADER: {
    DEVICE_ID: 'website',
    DEVICE_OS: 'browsers',
    APP_VERSION: '1.0.0',
    USER_AGENT: 'browser',
  }
};

export const REST_API = {
  AUTH: {
    LOGIN: `auth/user`,
    REGISTER: `auth/register`,
    FORGOT_PASSWORD: `auth/forgot-password`,
    RESET_PASSWORD: `auth/reset-password`,
    LOGOUT: `auth/logout`
  },
  USER: `user`,
  ME: {
    PROFILE: `me`,
    CHANGE_PASSWORD: `me/password`,
    FIREBASE_TOKEN: `auth/firebase`,
    UPDATE_DEVICE: `me/devices`,
  },
  ROLE: `roles`,
  MEDIA: `media`,
  FILE: `media/files`,
  SETTING: `webinfo`,
  BANNER: `banner`,
  NEWS: `news`,
  RATING: `rating`,
  CAR: `car`,
  CAR_ADMIN: `car/admin`,
  POSITIVE_NUMBER: `positivenumber`,
  CAR_BRAND: `carbrand`,
  CAR_BODY_STYLE: `carbodystyle`,
  CAR_ATTRIBUTE: `carattribute`,
  CAR_SERVICE: `carservices`,
  BILL_RULE: `rulebill`,
  PROMOTION: `promotion`,
  CATERORY: `categories`,
  TRANSACTION: `transaction`,
  CUSTOMER_REQUEST_BOOKING: `customer_request_booking`,
  CALCULATE: `transaction/calculatebooking`,
  MONEY_TRANSACTION: `moneytransaction`,
  MONEY_TRANSACTION_PERIOD: `transaction_period`,
  MONEY_TRANSACTION_CAR_EXTRAS: `moneytransaction/car_extras`,
  NOTIFICATION_ACTION: `notification_action`,
  REPORT: {
    CUSTOMER_SUMMARY: `customer_summary`,
    CAR_OWNER_SUMMARY: `carowner_summary`,
  },
  BILL: 'bill',
  PAYMENT_CHECK: 'payment_check',
  PAYMENT_CHECK_TOTAL: 'payment_check/payment_check_total',
  REASON: 'payment_check/reason',
};

export const IMAGE_ORIENTATION = {
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
};

export const MAX_SIZE_UPLOAD = 10; // 10 MB
export const IMAGE_EXTENSION = 'jpg|jpeg|png|JPG|JPEG|PNG';
export const DOCUMENT_EXTENSION = 'pdf|PDF';
export const FILE_EXTENSION = 'csv';
export const ZIP_EXTENSION = 'zip';
export const MAX_TITLE = 255;
export const MAX_DESC = 2500;
export const MAX_LENGTH_PHONE = 11;
export const MAX_LENGTH_NUMBER = 255;
export const MAX_LENGTH_PASSWORD = 255;

export const MOMENT_DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MMM_YY: 'DD MMM YY',
  DD_MMM_YY_H_m: 'DD MMM YY H:m',
  MM_DD_YYYY: 'MM-DD-YYYY',
  DD_MM_YYYY: 'DD-MM-YYYY',
  YYYY_MM_DD_H_m: 'YYYY-MM-DD H:m',
  MM_DD_YYYY_H_m: 'MM-DD-YYYY H:m',
  DD_MM_YYYY_H_m: 'DD-MM-YYYY H:m',
  DD_MMMM_YYYY_hh_mm_A: 'DD MMMM YYYY, hh:mm A',
  HH_MM: 'HH:mm',
  h_mm_a: 'h:mm A',
  MM_YYYY: 'MMM-YYYY',
  SEARCH_CAR: 'DD MMMM',
};

export const TIME_ZONE = {
  TIME_ZONE_DEFAULT: 'Asia/Ho_Chi_Minh',
  TIME_ZONE_UTC: 'UTC',
  VIETNAM: 'Asia/Ho_Chi_Minh',
};

export const LANGUAGE = {
  ENGLISH: 'en',
  VIETNAM: 'vi',
};

export const HTTP_CONNNECTION_TIMEOUT = 300000; // 5 minutes

export const MESSAGE_INFO = {
  MI_HTTP_CONNNECTION_TIMEOUT: 'Connection is timeout exceeded',
  MI_CHAT_SESSION_STOP: 'The chat session has been stopped',
};

export const DATEPICKER_FORMAT = {
  YYYY_MM_DD: 'yyyy-mm-dd',
};

export const DELETE_STATUS = {
  NO: false,
  YES: true
};

export const ENABLE_STATUS = {
  NO: false,
  YES: true
};

export const INPUT_MAXLENGTH = 255; // characters
export const INPUT_PASSWORD_MAXLENGTH = 6; // characters

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const MIN_AUTO_COMPLETE_FILTER_CHAR = 2;
export const DEPLAY_AUTO_COMPLETE_FILTER_CHAR = 200;
export const NEWS = {
  TYPE: {
    STORY: 'STORY',
    NEWS: 'NEWS',
    SLIDER: 'SLIDER',
    ABOUT0: 'ABOUT0',
    MAP: 'MAP',
    NHAN_VIEN: 'NHANVIEN',
    SERVICE: 'SERVICE',
    BLOG: 'blog',
  }
};

export const ITEM_LIMIT = {
  LIST_BOOKING_CAR: 9,
};


export const NEWS_TYPE = {
  BLOG: 'blog',
  ARTICLE: 'article',
  POPULAR_PLACE: 'popular_place',
  TOP_DEAL: 'top_deal'
};

export const BANNER_TYPE = {
  HOME: 'home_page',
  CAR_LIST: 'car_list_page',
  CAR_DETAIL: 'car_detail_page',
  ABOUT_US: 'about_us',
  CONTACT_US: 'contact_us',
  BLOG: 'blog',
  BLOG_DETAIL: 'blog_detail',
  ARTICLE: 'article',
};

export const CKEDITOR_CONFIG = {
  filebrowserUploadUrl: 'https://rest.bongtrip.vn/media',
  height: 600,
  debounce: 500
};

export const CAR_SERVICE_TYPE = {
  INSURRANCE: 'insurrance',
  ADDITIONAL: 'additional',
  HALF_DAY_MORNING: 'HalfDayMorning',
  HALF_DAY_AFTERNOON: 'HalfDayAfternoon'
};

export const CAR_IMAGE_COVER = [
  'assets/img/cars/car_cover_0.png',
  'assets/img/cars/car_cover_1.png',
  'assets/img/cars/car_cover_2.png',
  'assets/img/cars/car_cover_3.png',
  'assets/img/cars/car_cover_4.png',
  'assets/img/cars/car_cover_5.png',
  'assets/img/cars/car_cover_6.png',
  'assets/img/cars/car_cover_7.png',
  'assets/img/cars/car_cover_8.png'
];

export const CAR_ATTRIBUTE_TYPE = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  YES_NO: 'YES_NO'
};

export const PROMOTION_TYPE = {
  MONEY: 'MONEY',
  PERCENT: 'PERCENT'
};
export const TRANSACTION_TYPE = {
  OFF_TYPE: 'Off',
  BOOK_TYPE: 'Booked',
  ON_GOING_TYPE: 'OnGoing',
  HALF_DAY_MORNING: 'HalfDayMorning',
  HALF_DAY_AFTERNOON: 'HalfDayAfternoon',
  COMPLETE: 'Complete',
  CANCEL: 'Cancel'
};

export const BILL_RULE_TYPE = {
  BILL_FIELD: 'BILL_FIELD',
  RULE: 'RULE'
};

export const BILL_RULE_BY = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  COUNT: 'COUNT',
  AUTO: 'AUTO',
  MANUAL: 'MANUAL'
};

export const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  AMOUNT: 'amount'
};

export const CATEGORY_TYPE = {
  BLOG: 'blog',
  ARTICLE: 'article',
  POPULAR_PLACE: 'popular_place',
  TOP_DEAL: 'top_deal'
};

export const MAP_LOCATION_TYPE = {
  AIRPORT: 'airport',
  STREET_ADDRESS: 'street_address',
  TRAIN_STATION: 'train_station',
  HOSPITAL: 'hospital'
};

export const PAYMENT_TYPE = {
  INTERNAL: "internal",
  INTERNATIONAL: "international",
  OFFLINE: "offline",
  CASH: "Cash",
  ATM: "ATM",
  VN_PAY: "VN_PAY",
  CREDIT_CARD: "CreditCard",
  OTA: "OTA",
};

export const BOOKING_TIME_DEFAULT = {
  START_TIME: "21:00",
  END_TIME: "21:00",
};

export const DATETIMEPICKER_FORMAT = {
  YYYY_MM_DD: 'Y-m-d',
  MM_DD_YYYY: 'm-d-Y',
  DD_MMM_YY: 'd-M-y',
  DD_MMM_YY_H_i: 'd-M-y H:i',
  DD_MM_YYYY_H_i: 'M/d/yy h:mm a',
  DD_MM_YYYY: 'd-m-Y',
  YYYY_MM_DD_H_m: 'Y-m-d H:i',
  MM_DD_YYYY_H_m: 'm-d-Y H:m',
  DD_MM_YYYY_H_m: 'd-m-Y H:m',
  HH_MM_SSS: 'H:i',
  DD_MMM: 'd-M',
  MMM_YYYY: 'M-Y'
};

export const REPORT = {
  LIST_DAY_OPTION: {
    '7_DAY': '7day',
    '30_DAY': '30day',
    '3_MONTH': '3month'
  }
};

export const NOTIFICATION_ACTION_TYPE = {
  TRANSACTION: "TRANSACTION",
  BILL: "BILL",
  MONEY_TRANSACTION: "MONEY_TRANSACTION",
  CAR_EXTRAS: "CAR_EXTRAS",
  CREATE_USER: "CREATE_USER",
  ALL: "ALL"
};

export const CONFIRM_STATUS = {
  WAITING_CONFIRM: "WAITING_CONFIRM",
  WAITING_SYSTEM_CONFIRM: "WAITING_SYSTEM_CONFIRM",
  CONFIRM: "CONFIRM",
};

// format saturn-datepicker: use @angular/material-moment-adapter
export const MOMENT_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  }, 
  display: {

    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

export const REASON = {
  ROOM: "room",
  DEPOSIT: "deposit",
  DEBT: "debt",
  ITEM_IN: "item_in",
  KIT_IN: "kit_in",
};

export const BUDGET_TYPE = {
  CASH: "Cash",
  ATM: "ATM",
  VN_PAY: "VN_PAY",
  CREDIT_CARD: "CreditCard",
  OTA: "OTA",
};