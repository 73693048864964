import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard, BaseService } from 'app/services';
import { Ng2PaginationModule } from 'ng2-pagination';
import { AppModal, MOMENT_FORMATS } from 'app/modules';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CKEditorModule } from 'ng2-ckeditor';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { DateRangePickerDirective } from './directives/date_range_picker.directive';
import { SanitizeHtmlPipe, PricePipe } from './pipes';
import { UserFilterComponent } from './user_filter/user_filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchLocationComponent } from './search_location/search_location.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';

// DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { DateTimePickerDirective } from './directives/datetime_picker.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ChartsModule } from 'ng2-charts';
import { CarFilterComponent } from './car_filter/car_filter.component';
import { InputMaskDirective } from './directives/input_mask.directive';
import { MoneyMaskDirective } from './directives/money_mask.directive';
import { SatNativeDateModule, SatDatepickerModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserSearchComponent } from './user_search/user_search.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DateTimeSinglePickerDirective } from './directives/datetime_single_picker.directive';
@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        Ng2PaginationModule,
        UiSwitchModule,
        CKEditorModule,
        Angular2AirDatepickerModule,
        NgSelectModule,
        PerfectScrollbarModule,
        ChartsModule,
        SlickCarouselModule,
        ReactiveFormsModule,
        SatDatepickerModule,
        SatNativeDateModule,
        CurrencyMaskModule
    ],
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        TranslateModule,
        FormsModule,
        Ng2PaginationModule,
        UiSwitchModule,
        CKEditorModule,
        Angular2AirDatepickerModule,
        DateRangePickerDirective,
        SanitizeHtmlPipe,
        UserFilterComponent,
        SearchLocationComponent,
        PricePipe,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        DateTimePickerDirective,
        TruncatePipe,
        ChartsModule,
        CarFilterComponent,
        InputMaskDirective,
        MoneyMaskDirective,
        ReactiveFormsModule,
        // date picker
		SatDatepickerModule,
        SatNativeDateModule,
        UserSearchComponent,
        CurrencyMaskModule,
        DateTimeSinglePickerDirective
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        DateRangePickerDirective,
        SanitizeHtmlPipe,
        UserFilterComponent,
        SearchLocationComponent,
        PricePipe,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        DateTimePickerDirective,
        TruncatePipe,
        CarFilterComponent,
        InputMaskDirective,
        MoneyMaskDirective,
        UserSearchComponent,
        DateTimeSinglePickerDirective
    ],
    providers: [
        AuthGuard,
        AppModal,
        BaseService,
        // format SatDatepickerModule
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS},
    ]
})
export class SharedModule { }
