import { BaseSearchModel } from './base.search.model';

export class SearchTransactionModel extends BaseSearchModel {
    public fromTime: string;
    public toTime: string;
    public typeStatus: string;
    public carOwnerId: string;
    public isSortByCreatedDate: boolean = false;
    public creatorId: string;
    public isDeleted: boolean;
    public carId: string;
    public isReject: boolean;
    public typeConfirm: string;
    public confirmFromTime: string;
    public confirmToTime: string;
}
