import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'price'
})
export class PricePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    } else {
      return 0;
    }
  }
}