import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, MoneyTransactionModel, MoneyTransactionCarExtrasModel, ResponseModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class MoneyTransactionService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.MONEY_TRANSACTION + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(MoneyTransactionModel, res.data);
                return res;
            });
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAllExtras(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.MONEY_TRANSACTION_CAR_EXTRAS + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(MoneyTransactionCarExtrasModel, res.data);
                return res;
            });
    }

    /**
     * Get detail
     * @param id
     */
    public findById(id: string): Promise<MoneyTransactionModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.MONEY_TRANSACTION}/${id}`)
            .then((res) => {
                return JsonMapper.deserialize(MoneyTransactionModel, res);
            });
    }

    /**
     * Create Extra
     * @param data
     */
    public createExtra(data: MoneyTransactionCarExtrasModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.MONEY_TRANSACTION_CAR_EXTRAS}`, JsonMapper.serialize(data))
            .then(() => {
                return ResponseModel.createSuccess();
            });
    }

    public deleteExtra(id: string): Promise<ResponseModel> {
        return this.makeHttpDelete(`${this.apiUrl}/${REST_API.MONEY_TRANSACTION_CAR_EXTRAS}/${id}`)
            .then(() => {
                return ResponseModel.deleteSuccess();
            });
    }

    public update(data: MoneyTransactionModel): Promise<ResponseModel> {
        data.user = null;
        data.bills = null;
        data.moneyTransactionPeriod = null;
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.MONEY_TRANSACTION}/update_payment`, JsonMapper.serialize(data))
            .then(() => {
                return ResponseModel.updateSuccess();
            });
    }
    
}