/**
 * Created by duy thieu on 2020.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';

export class PaymentCheckTotalModel extends BaseModel {
  @Json('value')
  public value: string = undefined;

  @Json('date')
  public date: string = undefined;

  constructor() {
    super();
  }
}