/**
 * Created by duy thieu on 2020.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { UserModel } from './user.model';

export class ShipTransferModel extends BaseModel {
  @Json("hotelId")
  public hotelId: string = undefined;

  @Json("fromUserId")
  public fromUserId: string = undefined;

  @Json("fromUser")
  public fromUser: UserModel = undefined;

  @Json("toUserId")
  public toUserId: string = undefined;

  @Json("toUser")
  public toUser: UserModel = undefined;

  @Json("password")
  public password: string = undefined;

  @Json("moneyIn")
  public moneyIn: number = undefined;

  @Json("moneyOut")
  public moneyOut: number = undefined;

  @Json("previousShipId")
  public previousShipId: string = undefined;

  @Json("totalMoney")
  public totalMoney: number = 0;

  @Json("des")
  public des: string = undefined;

  @Json("previous")
  public previous: ShipTransferModel = undefined;

  constructor() {
    super();
  }
}