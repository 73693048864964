import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, BillModel, ResponseModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class BillAdminService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.BILL + '/admin_booking?' + queryString);
    }

    /**
   * Get detail
   * @param id
   */
    public findById(id: string): Promise<BillModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.BILL}/admin_booking/${id}`)
            .then((res) => JsonMapper.deserialize(BillModel, res));
    }

    /**
     * Create
     * @param data
     */
    public create(data: BillModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.BILL}/admin_booking`, JsonMapper.serialize(data));
    }
    /**
     * Update
     * @param data
     */

    public update(data: BillModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/${REST_API.BILL}/${data.id}/admin_booking`, JsonMapper.serialize(data))
            .then(() => {
                return ResponseModel.updateSuccess();
            });
    }

    public delete(id: string): Promise<ResponseModel> {
        return this.makeHttpDelete(`${this.apiUrl}/${REST_API.BILL}/admin_booking/${id}`)
            .then(() => {
                return ResponseModel.deleteSuccess();
            });
    }

    public calculate(data: BillModel): Promise<BillModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.BILL}/admin_booking/calculate`, JsonMapper.serialize(data))
            .then((res) => JsonMapper.deserialize(BillModel, res));
    }

    public export(filter: any = {}): Promise<any> {
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.downloadFile('GET', `${this.apiUrl}/` + REST_API.BILL + '/admin_booking/export?' + queryString)
    }
}