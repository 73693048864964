import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { ReportCustomerSummaryModel, ReportCarOwnerSummaryModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class ReportService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    public getCarOwnerSummary(fromTime: string, toTime: string): Promise<ReportCarOwnerSummaryModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.REPORT.CAR_OWNER_SUMMARY}?fromTime=${fromTime}&toTime=${toTime}`)
        .then(res => JsonMapper.deserialize(ReportCarOwnerSummaryModel, res));
    }

    public getCarCustomerSummary(fromTime: string, toTime: string): Promise<ReportCustomerSummaryModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.REPORT.CUSTOMER_SUMMARY}?fromTime=${fromTime}&toTime=${toTime}`)
        .then(res => JsonMapper.deserialize(ReportCustomerSummaryModel, res));
    }
}