import { Injectable } from '@angular/core';
import { ReduxState, ReduxStateProvider } from '@harmowatch/ngx-redux-core';
import { UserModel } from '../../models';

export interface AccountState {
    profile: UserModel;
}

@Injectable()
@ReduxState({ name: 'account-module' })
export class AccountStateProvider extends ReduxStateProvider<AccountState> {

    public static readonly DEFAULT_STATE: AccountState = {
        profile: new UserModel
    };

    getInitialState(): Promise<AccountState> {
        return Promise.resolve(AccountStateProvider.DEFAULT_STATE);
    }
}
